import React, { useEffect, useState } from 'react'
import Wrapper from './style'
import { Progress } from 'antd';
import { APIURL, themeColor } from "../../../Assets/Constant"
import domain from "../../../Assets/Images/domain.svg"
import { useSelector } from "react-redux"
import axios from 'axios';
import { ButtonFilled } from '../../Reuseable/UIButtons';

function DomainCreation() {
    const recordInfo = useSelector((state) => state.globalState.recordInfo)
    const registerForm = useSelector((state) => state.globalState.registerForm)
    const [showButton, setShowButton] = useState(false)
    const [progress, setProgress] = useState(0);
    const checkRecord = async () => {
        try {
            const payload = {
                changeId: recordInfo.Id
            }
            const response = await axios.post(
                `${APIURL}/user/check-record`,
                payload
            )
            if (response.data.status == true && response.data.message === "INSYNC") {
                setProgress(100);
                setShowButton(true)
            }
            else {
                setProgress(prevProgress => (prevProgress < 80 ? prevProgress + 20 : prevProgress));
            }
        }
        catch (e) {
            console.log("[checkRecord]ERROR:", e)
        }
    }
    useEffect(() => {
        // Set up an interval to call checkRecord every 5 seconds
        const intervalId = setInterval(() => {
            checkRecord();
        }, 5000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);
    return (
        <Wrapper >
            <h3>Your company URL is being generating, kindly do not refresh the page</h3>

            <img src={domain} alt="domain" />
            {!showButton && (
                <>
                    <p>Creating Domain please wait...</p>
                    <br/>
                    <Progress style={{maxWidth: "400px"}} percent={progress} status="active" strokeColor={{ from: themeColor, to: themeColor }} />
                </>
            )}
            {showButton && (
                <>
                 <p>Domain Created Successfully</p>
                    <ButtonFilled
                        onClick={() => {
                            window.open(`https://${registerForm?.domain}.waila.co/signin`, '_self')
                        }}
                    >
                        Navigate to your domain
                    </ButtonFilled>
                </>
            )}


        </Wrapper>
    )
}

export default DomainCreation