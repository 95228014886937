import { Outlet } from "react-router-dom"
import Header from "../WorkSpace/Component/Header"
import { WorkSpaceJson } from "../../../JsonFormat/WorkSpaceJson"
import Footer from "../WorkSpace/Component/Footer"
import ScrollToTopButton from "../../Reuseable/ScrollToTop"

const Layout = () => {
  return (
    <>
      <Header header={WorkSpaceJson.header} />
      <Outlet />
      <Footer footer={WorkSpaceJson.footer} />
      <ScrollToTopButton/>
    </>
  )
}

export default Layout
