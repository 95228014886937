import React, { useState, useEffect } from "react"
import HeaderWrap from "./style"
import { ButtonOutlined, ButtonLink, ButtonFilled } from "../../../../Reuseable/UIButtons"
import Offcanvas from "../Offcanvas"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import MenuIcon from "@mui/icons-material/Menu"
import MobileOffCanvas from "../MobileOffCanvas"
import { useDispatch, useSelector } from "react-redux"
import { setCloseDrawer, setShowRegisterModal } from "../../../../../redux/globalState"
import UIModal from "../../../../Reuseable/UIModal"
import RegisterModal from "../RegisterModal"
import { Link, useNavigate } from "react-router-dom"
import { ScrollToTop } from "../../../../../Assets/Constant"
import { useLocation } from 'react-router-dom';

function Header({ header }) {
  const [subItem, setSubItem] = useState({})
  const [isMobile, setIsMobile] = useState(false)
  const [mobileCanvas, setMobileCanvas] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const showRegisterModal = useSelector((state) => state.globalState.showRegisterModal)
  const showDrawer = useSelector((state) => state.globalState.closeDrawer)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) 
      setMobileCanvas(false)
    }

    handleResize() 
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])



  const handleClose = () =>   {dispatch(setCloseDrawer(false))}
  const handleShow = (val) => {
    if (val?.subItems.length > 0) {
      dispatch(setCloseDrawer(true))
      setSubItem(val)
    }
    if (val?.component) {
      dispatch(setCloseDrawer(true))
      setSubItem(val)
    }
  }

  const handleMobileOffcanvas = () => {
    setMobileCanvas(!mobileCanvas)
  }

  const handleBtnClick = (url) => {
    navigate(url)
    ScrollToTop(); 
  }

  const location = useLocation();
  const route = location.pathname; // Get the current route

  const [actionBtn, setActionBtn] = useState(getActionBtn(route));

  useEffect(() => {
    const updateActionBtn = () => {
      setActionBtn(getActionBtn(route));
    };

    updateActionBtn();

  }, [route]);

  function getActionBtn(route) {
    if (route === "/signin") {
      return [header?.ActionButtonList[1]];
    } else if (route === "/register") {
      return [header?.ActionButtonList[0]];
    } else {
      return header?.ActionButtonList;
    }
  }

  return (
    <HeaderWrap>
      <div className='header-container mx-5'>
        <div className='header-items'>
          <div
            className='logo'
            onClick={() => {
              navigate("/")
              dispatch(setCloseDrawer(false))
              ScrollToTop()
            }}
          >
            <img src={header?.Logo} alt='logo' />
          </div>
          {!isMobile && (
            <div className='nav-item'>
              {header?.MenuList?.map((val) => {
                if(val?.url){
                  return(
                    <Link  onMouseOver={handleClose} onClick={ScrollToTop} className="li" to={val?.url} target={val?.url?.startsWith('/') ? '_self' : '_blank'}>
                       {val?.title}
                    </Link>
                  )
                }
                else {
                  return (
                    <li
                      className="li"
                      onMouseOver={() => {
                        handleShow(val)
                      }}
                      onClick={() => {
                        if (showDrawer) {
                          dispatch(setCloseDrawer(false))
                        } else {
                          handleShow(val)
                        }
                      }}
                      key={val?.Id}
                    >
                      {val?.title}
                      {(val?.subItems.length > 0 || val?.component) && (
                        <KeyboardArrowDownIcon className='icon' />
                      )}
                    </li>
                  )
                }
              })}
            </div>
          )}
        </div>
        <div className="w-100 h-100 "  onMouseOver={handleClose}></div>
        {!isMobile && (
          <div className='header-btns' onMouseOver={handleClose}>
            {actionBtn?.map((val) => {
              return (
                <>
                  {val?.type === "outlined" && (
                    <ButtonOutlined onClick={() => handleBtnClick(val?.url)}>{val?.name}</ButtonOutlined>
                  )}
                  {val?.type === "link" && <ButtonLink>{val?.name}</ButtonLink>}
                  {val?.type === "filled" && (
                    <ButtonFilled
                      // onClick={() => {
                      //   dispatch(setShowRegisterModal(true))
                      // }}
                      onClick={() => {handleBtnClick(val?.url)}}
                    >
                      {val?.name}
                    </ButtonFilled>
                  )}
                </>
              )
            })}
          </div>
        )}
        {isMobile && (
          <div className='menu-button'>
            <MenuIcon onClick={handleMobileOffcanvas} />
          </div>
        )}
      </div>

      {showDrawer && <Offcanvas handleClose={handleClose} Item={subItem} />}
      {mobileCanvas && (
        <MobileOffCanvas
          handleClose={handleMobileOffcanvas}
          Item={header}
          handleShow={handleShow}
          handleMobileOffcanvas={handleMobileOffcanvas}
        />
      )}

      <UIModal
        open={showRegisterModal}
        onClose={() => {
          dispatch(setShowRegisterModal(false))
        }}
        header={<div>Select User Type</div>}
        size='md'
      >
        <RegisterModal />
      </UIModal>
    </HeaderWrap>
  )
}

export default Header
