import React from 'react'
import Wrapper from './style'
import { ButtonFilled } from '../../../../Reuseable/UIButtons'
import { useDispatch } from "react-redux"
import { setRegisterType, setShowRegisterModal } from "../../../../../redux/globalState"
import { useNavigate } from 'react-router-dom';
import { ScrollToTop } from '../../../../../Assets/Constant'

function RegisterModal() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleType = (registerType) =>{
        dispatch(setRegisterType(registerType))
        navigate("register")
        dispatch(setShowRegisterModal(false))
        ScrollToTop()
    }

    return (
        <Wrapper>
            <p>Let us know who you are logging in as so that we can provide you with relevant content.</p>
            <div className='button-group'>
                <ButtonFilled className='button' onClick={() => handleType("personal")} >For Personal</ButtonFilled>
                <ButtonFilled className='button' onClick={() => handleType("organization")} >For Organization</ButtonFilled>
            </div>
        </Wrapper>
    )
}

export default RegisterModal