import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { ScrollWrapper } from './style';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: 'smooth',
    });
  };
  return (
    <ScrollWrapper>
      {isVisible && (
        <button className="scroll-to-top-button" onClick={scrollToTop}>
          <KeyboardDoubleArrowUpIcon/>
        </button>
      )}
    </ScrollWrapper>
  );
};
export default ScrollToTopButton;