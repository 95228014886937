import React from "react"
import IntroDetails from "../WorkSpace/Component/IntroDetails"
import { CRMJson } from "../../../JsonFormat/CRMJson"

const CRM = () => {
  return (
    <div>
      <IntroDetails content={CRMJson.introduction} />
    </div>
  )
}

export default CRM
