import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

const initialState = {
  alertMessage: "",
  showRegisterModal: false,
  resgisterType: "organization",
  registerForm: {},
  productDetails: [],
  recordInfo: {},
  closeDrawer: false,
  country: "",
  userPlan: [
    {
      id: 1,
      product: "Tymeplus",
      monthlyCost: 7,
      noOfUsers: 1,
      period: "12 months",
      totalCost: 84,
      selected: false,
    },
    {
      id: 2,
      product: "TracTask",
      monthlyCost: 7,
      noOfUsers: 1,
      period: "12 months",
      totalCost: 84,
      selected: false,
    },
    {
      id: 3,
      product: "FieldSale",
      monthlyCost: 7,
      noOfUsers: 1,
      period: "12 months",
      totalCost: 84,
      selected: false,
    },
    {
      id: 4,
      product: "CRM",
      monthlyCost: 7,
      noOfUsers: 1,
      period: "12 months",
      totalCost: 84,
      selected: false,
    },
  ],
  planType: 'free',
  planCost : {
    upfrontCost: 0,
    monthlyCost: 0,
    yearlyCost: 0,
  }
}

const ToastContainer = ({message}) => (
  <div style={{ paddingRight: "20px"}}>
  {message}
</div>
);
export const globalState = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setAlertMessage: (state, action) => {
        toast(<ToastContainer message={action.payload.message} />, {
        type: action.payload.type,
        position: "top-center",
        autoClose: 60000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    },
    setShowRegisterModal: (state, action) => {
      state.showRegisterModal = action.payload
    },
    setRegisterType: (state, action) => {
      state.resgisterType = action.payload
    },
    setRegisterForm: (state, action) => {
      state.registerForm = action.payload
    },
    setProductDetails: (state, action) => {
      state.productDetails = action.payload
    },
    setRecordInfo: (state, action) =>{
      state.recordInfo = action.payload
    },
    setCloseDrawer: (state, action) => {
      state.closeDrawer = action.payload
    },
    setCountry: (state,action) => {
      state.country = action.payload
    },
    setUserPlan: (state,action) => {
      state.userPlan = action.payload
    },
    setPlanType: (state,action) => {
      state.planType = action.payload
    },
    setPlanCost: (state,action) => {
      state.planCost = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setAlertMessage,
  setShowRegisterModal,
  setRegisterType,
  setRegisterForm,
  setProductDetails,
  setRecordInfo,
  setCloseDrawer,
  setCountry,
  setUserPlan,
  setPlanType,
  setPlanCost
} = globalState.actions

export default globalState.reducer
