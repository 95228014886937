import Styled from "styled-components"

export const Wrapper = Styled.div`
padding: 2%;
span{
    font-size: 18px;
}
.pipe {
    border-left: 2px solid #e8e8e8;
    /* padding-left: 1rem; */
}

.MuiButtonBase-root {
    padding: 0;
}

.MuiOutlinedInput-input {
    padding: 4px 8px;
}

.discount {
    font-weight: 300;
    font-size: 11px;
}

.estimation-heading {
    font-weight: 500;
    font-size: 15px;
}

.cost {
    font-weight: 400;
    font-size: 16px;
}
td{
    vertical-align: middle;
    font-size: 14px;
}
.align-right{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-right: 6%;
}

`
