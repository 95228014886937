import React, { useEffect } from "react"
import Wrapper from "./style"
import CloseIcon from "@mui/icons-material/Close"
import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

function Offcanvas({ handleClose, Item }) {
  const iconStyle = { color: "#474747", cursor: "pointer" }
  useEffect(() => {
    const body = document.body
    const overflowStyle = body.style.overflow

    if (Item) {
      body.style.overflow = "hidden"
    } else {
      body.style.overflow = overflowStyle
    }

    return () => {
      body.style.overflow = overflowStyle
    }
  }, [Item])

  return (
    <Wrapper>
      <div className='float-right'>
        <CloseIcon onClick={handleClose} style={iconStyle} />
      </div>
      {/* <div className='main-container'> */}
      <Canvas Item={Item} handleClose={handleClose} />
      {/* </div> */}
      <div className='overlay' onMouseOver={handleClose}></div>
    </Wrapper>
  )
}

export default Offcanvas

const Canvas = ({ Item, handleClose }) => {
  if (Item?.subItems?.length === 0 && Item.component) {
    return Item.component
  }

  return (
    <>
      <div className='left-container'>
        {/* <span className='title'>{Item?.title}</span> */}
        {/* <hr /> */}
        {/* <br /> */}
        <Row>
          {Item?.subItems?.map((val) => {
            return (
              <Col>
                <span className='title'>{val?.title}</span>
                <hr />
                <div className='list-items'>
                  {val?.subItems?.map((list) => {
                    return (
                      <>
                        {list?.logo && <img src={list?.logo} alt='icon' />}
                        <div className='items'>
                          <Link to={list.url} onClick={handleClose}>
                            <figcaption className='subTitle'>{list?.title}</figcaption>
                          </Link>
                          <span>{list?.subTitle}</span>
                        </div>
                      </>
                    )
                  })}
                </div>
              </Col>
            )
          })}
        </Row>
      </div>
      {/* <div className='right-container'>
        <div className='list'>
          {Item?.subNavigators.map((list) => {
            return <h6>{list?.title}</h6>
          })}
        </div>
      </div> */}
    </>
  )
}
