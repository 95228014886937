import Styled from "styled-components"
import { fadethemeColor, themeColor } from "../../../Assets/Constant"

export const ButtonOutlined = Styled.button`
  padding :  10px 20px 10px 20px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  background: transparent;
  color: ${themeColor};
  white-space: nowrap;
  &:hover{
    background: #F1F3F4;
    color: ${fadethemeColor};
    border: 1px solid ${fadethemeColor};
  }
`

export const ButtonLink = Styled.button`
  padding :  10px 20px 10px 20px;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: ${themeColor};
  white-space: nowrap;
  outline: none;
  &:hover{
    outline: none;
    color: ${fadethemeColor};
  }

`

export const ButtonFilled = Styled.button`
  padding: 10px 20px 10px 20px;
  border: none;
  border-radius: 5px;
  background: ${(props) => (props.disabled ? '#ccc' : "#c2fae573")};
  color: #474747;
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  
  &:hover {
    background: ${(props) => (props.disabled ? '#ccc' : themeColor)};
  }
`;

