import React, { useEffect } from "react";
import Wrapper from "./style";
import { Link } from "react-router-dom";
import { ScrollToTop, countryList } from "../../../../../Assets/Constant";
import { Calendar, Select } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setCloseDrawer, setCountry } from "../../../../../redux/globalState";

function Footer({ footer }) {
  const country = useSelector((state) => state.globalState.country);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchPublicIp = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        getCountryInfo(response.data.ip);
      } catch (error) {
        console.error("Error fetching public IP:", error);
      }
    };
    fetchPublicIp();
  }, []);
  const getCountryInfo = async (ip) => {
    try {
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      dispatch(setCountry(response.data.country_name));
    } catch (error) {
      console.log("Error fetching country info:", error);
      return null;
    }
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="socialmedia">
          <p>{footer?.socialMediaQoutes}</p>
          <div className="icons">
            {footer?.socialMediaIcons.map((icon, i) => {
              return (
                <Link onClick={ScrollToTop} to={icon.url}>
                  <img
                    src={icon?.icon}
                    key={i}
                    alt={`footer-icon-${i}`}
                    className="footer-icon"
                  />
                </Link>
              );
            })}
          </div>
          <div>
            <Select
              placeholder="Select Country"
              style={{ minWidth: "200px" }}
              options={countryList?.map((item) => ({
                value: item,
                label: item,
              }))}
              allowClear
              value={{ value: country, label: country }}
              showSearch={true}
            />
          </div>
        </div>
        <div className="sites">
          {footer?.listSites.map((val) => {
            return (
              <div className="list-container">
                <h6>{val?.title}</h6>
                {val?.subItems.map((item) => {
                  return (
                    <li onClick={ScrollToTop}>
                      <Link
                        to={item?.url}
                        target={item?.url?.startsWith("/") ? "_self" : "_blank"}
                      >
                        {item?.name}
                      </Link>
                    </li>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="last-details mb-5">
          <p>{footer?.siteTitle}</p>
          <Link onClick={ScrollToTop} to={footer?.privacy?.url}>
            {footer?.privacy?.name}
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}
export default Footer;
