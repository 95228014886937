import Styled from "styled-components";

const Wrapper = Styled.div`
    padding: 2%;
    .button-group{
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-height: 150px;
        .button{
            min-width: 200px;
           
        }
        @media (max-width: 1000px) {
            flex-direction: column;
        }
    }

`;

export default Wrapper;
