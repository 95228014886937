import Styled from "styled-components"
import { themeColor } from "../../../../../Assets/Constant"

const Wrapper = Styled.div`
   margin: 5%;
   padding: 5%;
   display: grid;
   place-items: center;
   text-align: center;
   background: #F8F8F8;
   position: relative;
   overflow: hidden;
   h1{
    font-size: 56px;
    max-width: 400px;
    margin: 20px;
    @media (max-width: 1000px) {
      font-size: 26px;
    }
   }
   .start{
     position: absolute;
     top: 0;
     right: 20px;
     margin-top: -140px;
     width: 200px;
     height: 200px;
     background: green;
     border-radius:10px;
     transform: rotate(45deg);
     @media (max-width: 1000px) {
      display: none;
    }
   }
   .circle1{
     position: absolute;
     top: 0;
     right: 0px;
     margin-right: -20px;
     margin-top: -40px;
     width: 100px;
     height: 100px;
     border: 5px solid ${themeColor};
     border-radius:50%;
     @media (max-width: 1000px) {
      display: none;
    }
   }
   .end{
     position: absolute;
     bottom: 0;
     left: 10px;
     width: 250px;
     height: 200px;
     margin-bottom: -140px;
     border-radius:50%;
     background: #FBBC04;
     @media (max-width: 1000px) {
      display: none;
    }
   }
   .circle2{
     position: absolute;
     bottom: 0;
     left: 160px;
     margin-left: 20px;
     margin-bottom: -80px;
     width: 150px;
     height: 100px;
     border: 5px solid red;
     border-radius: 10px;
     @media (max-width: 1000px) {
      display: none;
    }
   }
`

export default Wrapper
