import React, { useEffect, useState } from "react";
import Wrapper from "./style";
import { PersonalFormJson } from "../../../JsonFormat/PersonalFormJson";
import GridBuilder from "../../UIBuilderEngine/GridBuilder";
import { WorkSpaceJson } from "../../../JsonFormat/WorkSpaceJson";
import CheckIcon from "@mui/icons-material/Check";
import { OrganizationFormJson } from "../../../JsonFormat/OrganizationFormJson";
import { useSelector } from "react-redux";
import ChangePasswordData from "../../../JsonFormat/ChangePasswordFormJson";
import OTPVerification from "../OTPVerification";
import {
  setRegisterForm,
  setRegisterType,
  setRecordInfo,
  setAlertMessage,
} from "../../../redux/globalState";
import { useDispatch } from "react-redux";
import axios from "axios";
import { APIURL, ScrollToTop } from "../../../Assets/Constant";
import { message } from "antd";
import { useNavigate } from "react-router";
import DomainCreation from "../DomainCreation";
import { Link } from "react-router-dom";
import PaymentProcess from "../PaymentProcess";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const planList = [
  { title: "Custom email with your domain" },
  { title: "Cloud-based, no installation neededn" },
  { title: "Advanced security and admin features" },
  { title: "Ease of mind with 24/7 support" },
  { title: "No charge for 14-day trial; monthly charge afterwards" },
];

const stripePromise = loadStripe(
  "pk_test_51Knzi8K5kiUDeKKvxZCzIpVnYjU2oPhQAupe7H4XGqKrMpdrY6pjVDaIj6xnEMFQ9Matm1kooRYS0jRUNGy0Qmil00LaQE3kgc"
);

function Registeration() {
  const resgisterType = useSelector((state) => state.globalState.resgisterType);
  const registerForm = useSelector((state) => state.globalState.registerForm);
  const userPlan = useSelector((state) => state.globalState.userPlan);
  const planType = useSelector((state) => state.globalState.planType);
  const planCost = useSelector((state) => state.globalState.planCost);

  const [loading, setLoading] = useState(false);
  const [formloading, setFormLoading] = useState(false);
  const [data, setData] = useState({});
  const [showComponent, setShowComponent] = useState(1);
  const { NewPasswordForm } = ChangePasswordData(data);
  const [domainStatus, setDomainStatus] = useState("");
  const [urlLoader, setUrlLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientSecretId, setClientSecretId] = useState("");
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: clientSecretId,
  // };

  // const clientSecretStrip = async () => {
  //   const payload = {
  //     name: registerForm?.name,
  //     email: registerForm?.email?.toLowerCase(),
  //     amount: "20",
  //     interval: "month",
  //     paymentType: "recurring",
  //   };
  //   try {
  //     const response = await axios.post(`${APIURL}/plan/{resource1}`, payload);
  //     if (response.data.status == true) {
  //       setClientSecretId(response.data.client_secret);
  //     } else {
  //       // message.error('OTP Invalid! Please enter the correct OTP');
  //     }
  //   } catch {
  //     setFormLoading(false);
  //   }
  // };

  const sendOTP = async (form = "") => {
    if (form != "") {
      dispatch(setRegisterForm(form));
    }
    const payload = {
      email: form ? form?.email : registerForm?.email,
    };
    try {
      setFormLoading(true);
      const response = await axios.post(`${APIURL}/user/send-otp`, payload);
      if (response.data.status == true) {
        setFormLoading(false);
        setShowComponent(2);
      } else {
        setFormLoading(false);
        dispatch(
          setAlertMessage({
            type: "error",
            message: response?.data.message,
          })
        );
        // message.error('OTP Invalid! Please enter the correct OTP');
      }
    } catch {
      setFormLoading(false);
    }
  };

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleOtpVerification = async (otp) => {
    const payload = {
      email: registerForm.email,
      otp: otp,
    };
    try {
      setLoading(true);
      const response = await axios.post(`${APIURL}/user/verify-otp`, payload);
      if (response.data.status == true) {
        setLoading(false);
        setShowComponent(3);
      } else {
        setLoading(false);
        dispatch(
          setAlertMessage({
            type: "error",
            message: "OTP Invalid! Please enter the correct OTP",
          })
        );
      }
    } catch {
      setLoading(false);
    }
  };

  const ProductList = {};

  if (planType === "recurring") {
    userPlan.forEach((item) => {
      ProductList[item.id.toString()] = {
        userCount: item.selected ? item.noOfUsers : 3,
        paymentType: item.selected ? "recurring" : "free",
        monthlyCost: item.selected ? item.monthlyCost.toString() : "0",
        totalCost: item.selected ? item.totalCost.toString() : "0",
        // interval: "month",
        storage: 2048
      };
    });
  } else {
    ProductList[1] =
      ProductList[2] =
      ProductList[3] =
      ProductList[4] =
        {
          userCount: 3,
          storage: 2048,
          monthlyCost: "0",
          totalCost: "0",
          paymentType: "free",
        };
  }

  const handleRegister = async (form) => {
    const payload = {
      email: registerForm?.email?.toLowerCase(),
      companyName: registerForm?.companyname,
      employeeCount: registerForm?.noofemp,
      country: registerForm?.country,
      password: form?.newPassword,
      type: resgisterType === "personal" ? "personal" : "company",
      firstName: registerForm?.firstname,
      lastName: registerForm?.lastname,
      domain: `${registerForm?.domain}.waila.co`,
      phone: registerForm?.phone,
      productList: ProductList,
      monthlyCost: planCost?.monthlyCost,
      totalCost: planCost?.yearlyCost,
      paymentType: planType,
      interval: "month"
    };
    try {
      setLoading(true);
      const response = await axios.post(`${APIURL}/user/register`, payload);
      if (response.data.status == true) {
        setLoading(false);
        dispatch(setRecordInfo(response.data.recordInfo));
        setShowComponent(4);
        // navigate('/signin')
      } else {
        setLoading(false);
        navigate("/signin");
        dispatch(
          setAlertMessage({
            type: "error",
            message: response.data.message,
          })
        );
      }
    } catch {
      setLoading(false);
    }
  };

  const handleType = (registerType) => {
    dispatch(setRegisterType(registerType));
  };

  const handleChange = async (e) => {
    if (e.target.name === "domain") {
      if (e.target.value.length === 0) {
        setDomainStatus("error");
        return;
      }
      try {
        setUrlLoader(true);
        const payload = {
          domain: `${e.target.value}.waila.co`,
        };
        const response = await axios.post(
          `${APIURL}/user/check-domain`,
          payload
        );
        if (response.data.status == true) {
          setDomainStatus("success");
          setUrlLoader(false);
        } else {
          setDomainStatus("error");
          setUrlLoader(false);
        }
      } catch {
        setUrlLoader(false);
      }
    }
  };

  const country = useSelector((state) => state.globalState.country);

  // useEffect(() => {
  //   clientSecretStrip();
  // }, []);

  return (
    <Wrapper>
      <div className="container main-container">
        <div className="left-container">
          {showComponent !== 5 && <h3>Let's get started</h3>}
          <br />
          {showComponent === 1 && (
            <>
              <GridBuilder
                formStructureJson={
                  resgisterType === "organization"
                    ? OrganizationFormJson
                    : PersonalFormJson
                }
                handleChange={handleChange}
                formSubmit={sendOTP}
                domainStatus={domainStatus}
                urlLoader={urlLoader}
                loading={formloading}
                initialValues={{ country: country }}
                //  handleClick={handleGoBack}
              />
              <br />
              <div className="signin-footer">
                <span className="text-center">
                  Already have a account?{" "}
                  <a
                    onClick={() => {
                      navigate("/signin");
                      ScrollToTop();
                    }}
                  >
                    Sign In
                  </a>
                </span>
                <p
                className="register-type"
                  onClick={() =>
                    handleType(
                      resgisterType === "organization"
                        ? "personal"
                        : "organization"
                    )
                  }
                >
                  {resgisterType === "organization"
                    ? "For personal"
                    : "For organization"}
                </p>
              </div>
            </>
          )}
          {showComponent === 2 && (
            <OTPVerification
              handleOtpVerification={handleOtpVerification}
              email={registerForm?.email}
              resendOtp={sendOTP}
              loading={loading}
            />
          )}
          {/* {showComponent === 3 && clientSecretId && (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret: clientSecretId }}
            >
              <PaymentProcess showComponent={() => setShowComponent(4)} />
            </Elements>
          )} */}

          {showComponent === 3 && (
            <GridBuilder
              formStructureJson={NewPasswordForm}
              handleChange={handleFormChange}
              formSubmit={handleRegister}
              loading={loading}
              //  handleClick={handleGoBack}
            />
          )}
        </div>
        {showComponent !== 4 && (
          <div className="right-container">
            <h2>Do more with Waila Marketplace</h2>
            <div className="products">
              {WorkSpaceJson?.introduction?.ProductsIcons?.map((val, i) => {
                // return <img className='product' alt='product' src={val.icon} />
                return (
                  <Link to={val.url} onClick={ScrollToTop}>
                    <img className="product" alt="product" src={val.icon} />
                  </Link>
                );
              })}
            </div>
            <div className="content-list">
              {planList?.map((val) => {
                return (
                  <div className="items">
                    <CheckIcon />
                    <li> {val?.title}</li>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {showComponent === 4 && <DomainCreation />}
    </Wrapper>
  );
}

export default Registeration;
