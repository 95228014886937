import React from "react"
import IntroDetails from "../WorkSpace/Component/IntroDetails"
import { TymeplusJson } from "../../../JsonFormat/TymePlusJson"

const Tymeplus = () => {
  return (
    <div>
      <IntroDetails content={TymeplusJson.introduction} />
    </div>
  )
}

export default Tymeplus
