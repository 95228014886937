import Styled from "styled-components"
import { themeColor } from "../../../../../Assets/Constant"

const Wrapper = Styled.div`
    .main-container{
        margin: 5%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .form-container{
        position: relative;
        h3{
            text-align: center;
        }
        .sub-title{
            padding-top: 5%;
            text-align: center;
        }
        .back-btn{
            left: 5px;
            top: 5px;
            position: absolute;
        }
        p{
            text-align: center;
            font-size: 15px;
            a{
                color: ${themeColor};
                cursor: pointer;
                font-weight: 600;
            }
            a:hover{
                color: ${themeColor}
            }
        }
        margin: 5%;
        min-width: 500px;
        max-width: 500px;
        min-height: 300px;
        padding: 3%;
        /* -webkit-box-shadow: 1px 1px 10px 0px rgba(184,184,184,1);
        -moz-box-shadow: 1px 1px 10px 0px rgba(184,184,184,1);
        box-shadow: 1px 1px 10px 0px rgba(184,184,184,1); */

        @media (max-width: 1000px) {
            min-width: 100%; 
            padding: 10%;
        }
    }
   
`

export default Wrapper
