import React from "react"
import IntroDetails from "../WorkSpace/Component/IntroDetails"
import { TracTaskJson } from "../../../JsonFormat/TracTaskJson"

const TracTask = () => {
  return (
    <div>
      <IntroDetails content={TracTaskJson.introduction} />
    </div>
  )
}

export default TracTask
