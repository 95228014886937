import React from 'react'
import Wrapper from './style'
import { useSelector } from "react-redux"
import { ButtonFilled } from '../../Reuseable/UIButtons';

function SwitchApp({ productList, submitLogin }) {
    const productdetails = useSelector((state) => state.globalState.productDetails)
    const result = productdetails
        ?.filter(product => productList[product.productId]) // Filter based on existence in productlist
        .map(product => ({ ...product, isTrue: productList[product.productId] })); // Add "included" property

    return (
        <Wrapper>
            <h4>Select the default Application</h4>
            <div className='button-group' >
                {result?.map((val) => {
                    return(
                    <>
                    <ButtonFilled className='button' onClick={() => submitLogin(val?.productId)}>{val?.productName}</ButtonFilled>
                    </>
                )})}
            </div>
        </Wrapper>
    )
}

export default SwitchApp