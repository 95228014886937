import tymeplusLogo from "../../Assets/Images/tymeplus.svg";
import tymeplusDashboard from "../../Assets/Images/tymeplus_dashboard.png";

import tymeplus1 from "../../Assets/Images/Tymeplus/tymeplus_1.png";
import tymeplus2 from "../../Assets/Images/Tymeplus/tymeplus_2.png";
import tymeplus3 from "../../Assets/Images/Tymeplus/tymeplus_3.png";
import tymeplus4 from "../../Assets/Images/Tymeplus/tymeplus_4.png";
import tymeplus5 from "../../Assets/Images/Tymeplus/tymeplus_5.png";
import tymeplus6 from "../../Assets/Images/Tymeplus/tymeplus_6.png";
import tymeplus7 from "../../Assets/Images/Tymeplus/tymeplus_7.png";
import tymeplus8 from "../../Assets/Images/Tymeplus/tymeplus_8.png";
import tymeplus9 from "../../Assets/Images/Tymeplus/tymeplus_9.png";
import tymeplus10 from "../../Assets/Images/Tymeplus/tymeplus_10.png";

export const TymeplusJson = {
  introduction: {
    ProductsIcons: [{ icon: tymeplusLogo, url: "" }],
    Details: {
      heading:
        "In today's Marketplace, teams of all sizes utilize diverse tools to connect, create, and collaborate seamlessly.",
      summary:
        "A flexible, innovative solution for people and organisations to achieve more.",
      buttonName: "Get Started",
    },
    image: tymeplusDashboard,
    Video: "",
    imageList: [
      tymeplus1,
      tymeplus2,
      tymeplus3,
      tymeplus4,
      tymeplus5,
      tymeplus6,
      tymeplus7,
      tymeplus8,
      tymeplus9,
      tymeplus10,
    ],
    VideoHeading:
      "Productivity and collaboration tools for all the ways that we work.",
    mobile: {
      download: true,
      gplayurl: "https://play.google.com/store/apps/details?id=com.tymeplus",
      appleurl: "https://apps.apple.com/us/app/tymeplus/id1558720408",
    },
  },
};
