import React from "react"
import { WorkSpaceJson } from "../../../../../JsonFormat/WorkSpaceJson"
import Wrapper from "./style"

function Banner() {
  return (
    <Wrapper>
      <div className='banner text-center text-bold'>
        <h2 className="banner_h">{WorkSpaceJson?.help}</h2>
        <p className="banner_p">{WorkSpaceJson?.banner}</p>
      </div>
    </Wrapper>
  )
}

export default Banner
