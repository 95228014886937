import Styled from "styled-components"
import { themeColor } from "../../../Assets/Constant"

const Wrapper = Styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 2%;
    h3{
        font-size: 18px;
        text-align: center;
    } 
    p{
        text-align: center;
    }
    img{
        height: 300px;
    }
`;

export default Wrapper
