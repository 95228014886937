import React from "react"
import IntroDetails from "../WorkSpace/Component/IntroDetails"
import { FieldSaleJson } from "../../../JsonFormat/FieldSaleJson"

const FieldSale = () => {
  return (
    <div>
      <IntroDetails content={FieldSaleJson.introduction} />
    </div>
  )
}

export default FieldSale
