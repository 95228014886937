import styled from "styled-components"
export const ScrollWrapper = styled.div`
.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #C2FAE5;
  color: #474747;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  outline: none;
}
`