import React, { useEffect } from "react"
import Layout from "../Layout"
import { APIURL } from "../../../Assets/Constant"
import axios from "axios"
import { useDispatch } from "react-redux"
import {  setProductDetails } from "../../../redux/globalState"

function Main() {
  const dispatch = useDispatch()
  const getProducts = async() => {
    try{
      const response = await axios.get(
        `${APIURL}/product/details`,
      )
      console.log("response.data -->", response.data)
      if (response.data.status == true) {
        dispatch(setProductDetails(response.data.productdetails))
        return
      }
    }catch{

    }
  }
  useEffect(() => {
    getProducts()
  })
  return <Layout />
}

export default Main
