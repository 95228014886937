import Styled from "styled-components"

const Wrapper = Styled.div`
   padding: 5%;
  .socialmedia{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 10px 10px 0;
    gap: 10px 60px;
    color: #616161;
    border-bottom: 1px solid #ccc;
    p{
        color: #616161;
        font-size: 16px;
        margin-top: 15px;
    }
    .icons{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        font-size: 30px;

        .footer-icon{
            cursor: pointer;
            height: 30px;
            width: auto;
            object-fit: contain;
            @media (max-width: 1000px) {
            height: 20px;
            gap: 20px;
            object-fit: contain;
          }
        }
      
    }
  }
  .sites{
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        height: 250px;
        flex-wrap: wrap;
        gap: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 30px;
        @media (max-width: 500px) {
          font-size: 26px;
          display: flex;
          flex-direction: column;
          height: auto;
          flex-wrap: wrap;
          gap: 30px;
        }
        .list-container{
            h6{
                color: #616161;
                font-size: 16px;
            }
            li {
             font-size:13px;
             padding-top: 10px;
             list-style: none;
             cursor: pointer;
             color: #616161;
             &:hover {
               color: #000;
             }
           }
        }
    }
    .last-details{
        display: flex;
        justify-content: space-between;
        p{
            margin-top : 20px;
            color: #616161;
            font-size: 16px;
            @media (max-width: 1000px) {    
              font-size: 12px 
            }
            
        }
        a{
            margin-top : 20px;
            color: #616161;
            font-size: 15px;
            cursor: pointer;
            @media (max-width: 1000px) {    
              font-size: 12px 
            }
        }
        @media (max-width: 320px) {    
          flex-direction: column;
          justify-content: flex-start;
        }
    }
`

export default Wrapper
