import Styled from "styled-components"
import { themeColor } from "../../../Assets/Constant"

const Wrapper = Styled.div`
   width: 100%;
   min-height: 500px;
   padding: 2%;
`;

export default Wrapper
