import crmDashboard from "../../Assets/Images/crm-dashboard.png";

import crmlogo from "../../Assets/Images/crm.svg";

import crm1 from "../../Assets/Images/CRM/crm_1.png";
import crm2 from "../../Assets/Images/CRM/crm_2.png";
import crm3 from "../../Assets/Images/CRM/crm_3.png";
import crm4 from "../../Assets/Images/CRM/crm_4.png";
import crm5 from "../../Assets/Images/CRM/crm_5.png";
import crm6 from "../../Assets/Images/CRM/crm_6.png";
import crm7 from "../../Assets/Images/CRM/crm_7.png";
import crm8 from "../../Assets/Images/CRM/crm_8.png";
import crm9 from "../../Assets/Images/CRM/crm_9.png";
import crm10 from "../../Assets/Images/CRM/crm_10.png";

export const CRMJson = {
  introduction: {
    ProductsIcons: [{ icon: crmlogo, url: "/crm" }],
    Details: {
      heading:
        "In today's Marketplace, teams of all sizes utilize diverse tools to connect, create, and collaborate seamlessly.",
      summary:
        "A flexible, innovative solution for people and organisations to achieve more.",
      buttonName: "Get Started",
    },
    image: crmDashboard,
    Video: "",
    imageList: [crm1, crm2, crm3, crm4, crm5, crm6, crm7, crm8, crm9, crm10],
    VideoHeading:
      "Productivity and collaboration tools for all the ways that we work.",
    mobile: {
      download: true,
      gplayurl: "https://play.google.com/store/apps/details?id=com.fieldsale",
      appleurl: "https://apps.apple.com/us/app/fieldsale/id1558720408",
    },
  },
};
