import Styled from "styled-components"

const Wrapper = Styled.div`
   .main-container{
     padding: 3%;
     display: flex;
     align-items: center;
     justify-content: space-evenly;
     gap: 20px;
     @media (max-width: 1000px) {
      flex-direction: column
    }
    .intro-image {
      min-width: 300px;
      max-width: 1000px;
      width: 100%;
      object-fit: contain;
      height: auto; /* Ensures the image scales proportionally */
    }
    .image_container{
      width:100%;
      height: 350px;
    }
    @media (max-width: 650px) {
      .image_container{
        height: 250px !important;
      }
    }
    @media (max-width: 400px) {
      .image_container{
        height: 150px !important;
      }
    }

    .buttons-container {
      flex-wrap: wrap;
      justify-content: center;
    }
     .content{
        margin-top: 20px;
        width: 100%;
        .products{

            display: flex;
            align-items: center;
            justify-content: start;
            flex-wrap: wrap;
            gap: 10px 30px;

            .product {
              width: auto;
              height: 35px;
              object-fit: contain;
              cursor: pointer;
            
            }
        }
        h1{
            font-size: 40px;
            font-weight: bolder;
            @media (max-width: 1000px) {
              font-size: 30px;
            }
        }
        p{
            margin-top: 20px;
            font-size: 20px;
            /* max-width: 500px; */
        }
        @media (max-width: 1000px) {
          text-align: center;
          .products{ 
            justify-content: center;
          }
        }
     }
   }
   .video-content{
     margin-top: 20px;
     display: grid;
     place-items: center;
     h2{
        font-size: 36px;
        max-width: 550px;
        text-align: center;
        font-weight: bold;
        @media (max-width: 1000px) {
          font-size: 25px;
          width: 100%;
        }
     }
     video{
        margin: 5%;
        /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);  */
        border-radius: 10px;
        width: 640px;
        height: 360px;
        @media (max-width: 1000px) {
          width: 340px;
          height: 240px;
        }
     }
   }
  
`

export default Wrapper
