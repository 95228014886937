import React, { useState } from "react"
import Wrapper from "./style"
import GridBuilder from "../../../../UIBuilderEngine/GridBuilder"
import GetSupportJson from "../../../../../JsonFormat/GetSupportFormJson"

const SupportForm = () => {
  const [data, setData] = useState({})

  const { SupportForm } = GetSupportJson(data)

  const handleFormChange = (e) => {
    console.log("e", e.target.value)
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmitForm = (e) => {
    console.log("e-->", e)
  }

  return (
    <Wrapper>
      <div className='container'>
        <div className='main-container'>
          <div className='form-container'>
            <GridBuilder
              formStructureJson={SupportForm}
              handleChange={handleFormChange}
              formSubmit={handleSubmitForm}
              //  loading={loading}
              //  handleClick={handleGoBack}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default SupportForm
