import Styled from "styled-components"

const Wrapper = Styled.div`
    position: relative;
    background: white; 
    border-top: 1px solid #ccc;
    z-index: 1000; 
    overflow: hidden;
    .float-right{
        position: absolute;
        right : 20px;
        top: 10px;
    }
    .main-container{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }
    .left-container {
        grid-area: 1 / 1 / 2 / 4;
        margin-left: 2%;
        padding: 2% 6% 4% 6%;
        .title{
            font-size: 18px;
        }  
        .subTitle{
            font-size: 12px;
            text-transform: uppercase; 
            font-weight: bold;
            color: #3c4043;
        }
        .list-items{
          margin-top: 20px;
          .items{
             margin-top: 20px;
             cursor: default;
             .heading{
                color: #3c4043;
                font-size: 14px;
                font-weight: 400;
            }
            span{
                font-size: 14px;
                color: #80868b;
            }
          }
        }
    }
    .right-container{
        grid-area: 1 / 4 / 2 / 6;
        background: #efefef;
        padding: 20px;
        place-items: center;
        .list{
            margin-top: 15%;
            margin-left: 10%;
            h6{
                padding: 10px;
                cursor: pointer;
            }
        }
    }
    .overlay {
        position: fixed !important;  
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1 !important; 
      
    }
   
`

export default Wrapper
