import styled from "styled-components"

export const Wrapper = styled.div`
  .image-col {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .heading {
    margin-top: 40px;
  }

  .form-container {
    width: 100%;
    padding: 20px;
    min-width: 300px;
    /* max-width: 500px; */
  }

  .business-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
`
