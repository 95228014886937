import React from 'react'
import { Container } from 'react-bootstrap';
import GridBuilder from '../../UIBuilderEngine/GridBuilder';
import { WorkSpaceJson } from '../../../JsonFormat/WorkSpaceJson';
import Wrapper from './style';

 const AddInsident = () => {
  const OnboardingForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [12, 12, 12, 12, 12, [, 4]],
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "Company Name",
              type: "text",
              name: "companyName",
              Placeholder: "Company Name",
              value: "companyName",
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Company Name`,
                },
              ],
              maxLength: 25,
              onKeyDown:  /^[a-zA-Z\s]+$/,
            },
          ],
          [
            {
              id: 2,
              label: "Location",
              type: "select",
              name: "location",
              Placeholder: "Location",
              value: "location",
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Location`,
                },
              ],
              options: WorkSpaceJson.footer.country,
              maxLength: 25,
              onKeyDown: /^[a-zA-Z\s]+$/,
            },
            {
              id: 3,
              label: "Employee Code",
              type: "text",
              name: "employeeId",
              Placeholder: "Employee Code",
              value: "employeeId",
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Employee Code`,
                },
              ],
              onKeyDown: /^[a-zA-Z0-9]+$/,
              maxLength: 20,
            },
          ],
          [
            {
              id: 4,
              label: "Employment Type",
              type: "select",
              name: "employmentType",
              Placeholder: "Employment Type",
              value: "employmentType",
              options: [
                "Full Time",
                "Part Time",
                "Contract", 
              ],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Employment Type`,
                },
              ],
             
            },
            {
              id: 5,
              label: "Job Title",
              type: "text",
              name: "jobTitle",
              Placeholder: "Job Title",
              value: "jobTitle",
              validationRules: [
                {
                  required: true,
                  message: `Please enter your Job Title`,
                },
              ],
              onKeyDown: /^[a-zA-Z\s]+$/,
              maxLength: 25,
            },
          ],
          [
            {
              id: 6,
              label: "Start Date",
              type: "date",
              name: "startDate",
              Placeholder: "Start Date",
              value: "startDate",
              validationRules: [
                {
                  required: true,
                  message: `Please select the Start Date`,
                },
              ],
            },
            {
              id: 7,
              label: "Probation Length",
              type: "text",
              name: "probation",
              //need this option
              // options: selectList,
              Placeholder: "Probation Length",
              value: "probation",
              validationRules: [
                {
                  required: true,
                  message: `Please select the Probation Length`,
                },
              ],
              onKeyDown: /^[a-zA-Z0-9]+$/,
              maxLength: 15,
            },
          ],
          [
            {
              id: 8,
              label: "Primary Manager",
              type: "text",
              name: "primaryNanager",
              Placeholder: "Primary Manager",
              value: "primaryManager",
              validationRules: [
                {
                  required: true,
                  message: `Please select the Primary Manager`,
                },
              ],
              onKeyDown: /^[a-zA-Z\s]+$/,
              maxLength: 20,
            },
            {
              id: 9,
              label: "Secondary Manager",
              type: "text",
              name: "secondaryManager",
              Placeholder: "Secondary Manager",
              value: "secondaryManager",
              validationRules: [
                {
                  required: true,
                  message: `Please select the Secondary Manager`,
                },
              ],
              onKeyDown: /^[a-zA-Z\s]+$/,
              maxLength: 20,
            },
          ],
          [
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 10,
              label: "Next",
              type: "div",
              position: "end",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
            {
              id: 11,
              label: "Back",
              type: "button",
              position: "end",
              background: "white", // Assuming you want a gray background
              textColor: "black",
              event: "click",
            },
            {
              id: 10,
              label: "Submit",
              type: "button",
              position: "end",
              background: "#C2FAE5",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  };

  return (
    <Wrapper>
      <div className='add_insident_container'>
        <Container>
          <h1 className='text-center mb-5'>Add insident</h1>
          <div className='form_container'>
            <GridBuilder
                formStructureJson={OnboardingForm}
                // handleChange={handleFormChange}
                // formSubmit={handleSubmitForm}
                // initialValues={data}
                // handleClick={handleClickForm}
                // handleBack={handleBackForm}
                // handleSelectChange={handleSelectChange}
              />
          </div>
          
        </Container>
      </div>
    </Wrapper>
  )
}
export default AddInsident;