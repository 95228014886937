import tracktaskLogo from "../../Assets/Images/tracktask.svg";
import introImage from "../../Assets/Images/intro.png";
import tracktask1 from "../../Assets/Images/TrackTask/tracktask_1.png";
import tracktask2 from "../../Assets/Images/TrackTask/tracktask_2.png";
import tracktask3 from "../../Assets/Images/TrackTask/tracktask_3.png";
import tracktask4 from "../../Assets/Images/TrackTask/tracktask_4.png";
import tracktask5 from "../../Assets/Images/TrackTask/tracktask_5.png";
import tracktask6 from "../../Assets/Images/TrackTask/tracktask_6.png";
import tracktask7 from "../../Assets/Images/TrackTask/tracktask_7.png";
import tracktask8 from "../../Assets/Images/TrackTask/tracktask_8.png";
import tracktask9 from "../../Assets/Images/TrackTask/tracktaks_9.png";
import tracktask10 from "../../Assets/Images/TrackTask/tracktask_10.png";

export const TracTaskJson = {
  introduction: {
    ProductsIcons: [{ icon: tracktaskLogo, url: "" }],
    Details: {
      heading:
        "In today's Marketplace, teams of all sizes utilize diverse tools to connect, create, and collaborate seamlessly.",
      summary:
        "A flexible, innovative solution for people and organisations to achieve more.",
      buttonName: "Get Started",
    },
    image: introImage,
    Video: "",
    imageList: [tracktask1, tracktask2, tracktask3, tracktask4, tracktask5, tracktask6, tracktask7,  tracktask8, tracktask9, tracktask10],
    VideoHeading:
      "Productivity and collaboration tools for all the ways that we work.",
    mobile: {
      download: true,
      gplayurl: "https://play.google.com/store/apps/details?id=com.tractask",
      appleurl: "https://apps.apple.com/us/app/tractask/id1558720408",
    },
  },
};
