const SignInFormData = (data) => {
   const SignInForm = {
    totalpages: 1,
    pages: [
        {
            modalView: false, // For showing modal in Popup
            colSpan: [12, 12, 12], // for 1 column 12, 2 columns 6, 3 columns 4, 4 columns 3 (max 4 columns)
            fields: [
                // Row 1
                [
                    {
                        id: 1,
                        label: "Email",
                        type: "text",
                        name: "email",
                        Placeholder: "Email",
                        value: data["email"],
                        validationRules: [
                            {
                              required: true,
                              message: `Please enter email`,
                            },
                            {
                                pattern: /^\S+@\S+\.\S+$/,
                                message: "Please enter a valid email address",
                            }
                        ],
                        maxLength: 50
                    },
                ],
                [
                    {
                        id: 2,
                        label: "Password",
                        type: "password",
                        name: "password",
                        Placeholder: "Password",
                        value: data["password"],
                        validationRules: [
                            {
                              required: true,
                              message: `Please enter password`,
                            },
                        ],
                        maxLength: 30
                    },
                ],
                [
                    {
                        type: "div",
                        content: "Forgot Password ?",
                        textColor: "orange",
                        background: "",
                        position: "end",
                        event: "click"
                    },
                ],
                [
                    {
                        id: 3,
                        label: "Sign In",
                        type: "button",
                        position: "center", // position options (end, start, center)
                        background: "Orange",
                        textColor: "white",
                        event: "submit",
                    },
                ]
            ],
        },
    ],
};

const ForgotForm = {
    totalpages: 1,
    pages: [
        {
            modalView: false, // For showing modal in Popup
            colSpan: [12, 12, 12], // for 1 column 12, 2 columns 6, 3 columns 4, 4 columns 3 (max 4 columns)
            fields: [
                // Row 1
                [
                    {
                        id: 1,
                        label: "Email",
                        type: "text",
                        name: "email",
                        Placeholder: "Email",
                        value: data["email"],
                        validationRules: [
                            {
                              required: true,
                              message: `Please enter email`,
                            },
                            {
                                pattern: /^\S+@\S+\.\S+$/,
                                message: "Please enter a valid email address",
                            }
                        ]
                    },
                ],
                [
                    {
                        id: 3,
                        label: "Send OTP",
                        type: "button",
                        position: "center", // position options (end, start, center)
                        background: "Orange",
                        textColor: "white",
                        event: "submit",
                    },
                ]
            ],
        },
    ],
};
return {
    SignInForm,
    ForgotForm
  };
};

export default SignInFormData;
