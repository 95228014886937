import Styled from "styled-components"
import { themeColor } from "../../../Assets/Constant"

const Wrapper = Styled.div`
    .main-container{
        display: flex;
        justify-content: center;
        margin-top: 5%;
        gap: 5%;
        .left-container{
           display:flex;
           flex-direction: column;
           align-items: start;
           justify-content: center;
            min-width: 400px;
            max-width: 400px;
           /* width: 100%; */
            @media (max-width: 1000px) {
             width: 100%;
             min-width: 100%;
             padding: 10%;
            }
            p{
                color: ${themeColor};
                text-align: center;
                cursor: pointer;
                font-weight: 600;
            }
            h3{
                text-align: center;
                width: 100%;
            }
            .signin-footer{
               display: flex;
               justify-content: space-between; 
               width: 100%;
               span{
                font-size: 14px;
               }
               a{
                color: ${themeColor};
                cursor: pointer;
                font-weight: bold;
               }
            }
        }
        .right-container{
            max-width: 450px;
            border: 1px solid #ccc;
            padding: 2%;
            border-radius: 20px;
            .content-list{
                padding: 5%;
                li{
                    list-style: none;
                    padding: 5%;
                }
                .items{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
            }
            @media (max-width: 1000px) {
              padding: 10%;
              width: 100%;
              max-width: 100%;
            }
           
        }
        @media (max-width: 1000px) {
          flex-direction: column;  
         
        }
    }
    .products{
        margin-top: 3%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 30px;

        .product {
        width: auto;
        height: 30px;
        object-fit: contain;
        cursor: pointer;
        @media (max-width: 1000px) {
            height: 20px;       
        }

        }

        @media (max-width: 1000px) {
            gap: 10px;
        }

       
    }

    @media (max-width: 400px) {
        .signin-footer{
            display: block !important;
        }   
        .register-type{
            padding-top: 15px;
        } 
    }
`;

export default Wrapper
