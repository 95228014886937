export const getInvestorSupportJson = (data) => {
  const SupportForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false,
        colSpan: [12, 12, 12],
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "First Name",
              type: "text",
              name: "firstName",
              Placeholder: "First Name",
              value: data["firstName"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your first name`,
                },
              ],
            },
            {
              id: 2,
              label: "Last Name",
              type: "text",
              name: "lastName",
              Placeholder: "Last Name",
              value: data["lastName"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your last name`,
                },
              ],
            },
          ],
          // Row 2
          [
            {
              id: 3,
              label: "Email",
              type: "text",
              name: "email",
              Placeholder: "Email",
              value: data["email"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter email`,
                },
                {
                  pattern: /^\S+@\S+\.\S+$/,
                  message: "Please enter a valid email address",
                },
              ],
            },
          ],
          // Row 3
          [
            {
              id: 4,
              label: "Country",
              type: "text",
              name: "country",
              Placeholder: "Country",
              value: data["country"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your country`,
                },
              ],
            },
            {
              id: 5,
              label: "Organization",
              type: "text",
              name: "organization",
              Placeholder: "Organization (Optional)",
              value: data["organization"],
            },
          ],
          // Row 4
          [
            {
              id: 6,
              label: "How can we help you",
              type: "textarea",
              name: "help",
              Placeholder: "Enter your query here",
              value: data["help"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter how we can help you`,
                },
              ],
            },
          ],
          // Row 6
          [
            {
              id: 8,
              label: "Submit",
              type: "button",
              position: "center",
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  }

  return {
    SupportForm,
  }
}
