import Styled from "styled-components";

const CarouselWrapper = Styled.div`

    width: 100%;
    height: 100%;

.fading-images-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
   
  }
  
  img.visible {
    opacity: 1;
  }
  
  img.hidden {
    opacity: 0;
  }

`;

export default CarouselWrapper;
