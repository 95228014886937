import React, {useEffect} from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./redux/store.js"
import Main from "./Components/Pages/MainPage"
import { ToastContainer } from "react-toastify"
import "./App.css"
import WorkSpace from "./Components/Pages/WorkSpace/index.js"
import SignIn from "./Components/Pages/SignIn/index.js"
import AddInsident from "./Components/Pages/AddInsident/index.js"
import Registeration from "./Components/Pages/Registeration/index.js"
import Tymeplus from "./Components/Pages/Tymeplus/index.js"
import FieldSale from "./Components/Pages/FieldSale/index.js"
import TracTask from "./Components/Pages/TracTask/index.js"
import InvestorSupport from "./Components/InvestorSupport/index.js"
import TermsCondition from "./Components/Pages/TermsAndConditions/index.js"
import Privacy from "./Components/Pages/privacyPolicy/index.js"
import CRM from "./Components/Pages/CRM/index.js"
import { toast } from "react-toastify"
import Calculator from "./Components/Pages/WorkSpace/Component/Calculator/index.js"

var createHost = require("cross-domain-storage/host")

function App() {
  createHost([
    {
      origin: "http://localhost:3000",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "http://localhost:3001",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "https://www.tymeplus.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "https://www.tractask.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "https://www.crm.waila.co",
      allowedMethods: ["get", "set", "remove"],
    },
    {
      origin: "http://localhost:3001/",
      allowedMethods: ["get", "set", "remove"],
    }
  ])

  useEffect(() => {
    const handleBodyClick = () => {
      // Dismiss the toast when clicking outside the toast container
      toast.dismiss();
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer autoClose={600000} style={{ width: 'auto', textAlign: "center", marginTop: "50px", }} closeButton={true} />
        <Routes>
          <Route path='/' element={<Main />}>
            <Route index element={<WorkSpace />} />
            <Route path='/support' element={<WorkSpace />} />
            <Route path='/investor' element={<InvestorSupport />} />
            <Route path='/signin' element={<SignIn />} />
            <Route path='/add-insident' element={<AddInsident />} />
            <Route path='/register' element={<Registeration />} />
            <Route path='/tymeplus' element={<Tymeplus />} />
            <Route path='/tractask' element={<TracTask />} />
            <Route path='/fieldsale' element={<FieldSale />} />
            <Route path='/crm' element={<CRM />} />
            <Route path='/calculator' element={<Calculator />} />
            {/* Catch-all route for undefined routes */}
            <Route path='*' element={<WorkSpace />} />
            {/* <Route path='/terms-condition' element={<TermsCondition />} /> */}
            <Route path='/Privacy' element={<Privacy />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App
