const GetSupportJson = (data) => {
  const SupportForm = {
    totalpages: 1,
    pages: [
      {
        modalView: false, // For showing modal in Popup
        colSpan: [12, 12, 12], // for 1 column 12, 2 columns 6, 3 columns 4, 4 columns 3 (max 4 columns)
        fields: [
          // Row 1
          [
            {
              id: 1,
              label: "Name",
              type: "text",
              name: "name",
              Placeholder: "Name",
              value: data["name"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your name`,
                },
              ],
            },
          ],
          [
            {
              id: 2,
              label: "Email",
              type: "text",
              name: "email",
              Placeholder: "Email",
              value: data["email"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter email`,
                },
                {
                  pattern: /^\S+@\S+\.\S+$/,
                  message: "Please enter a valid email address",
                },
              ],
            },
          ],
          [
            {
              id: 3,
              label: "Query",
              type: "textarea",
              name: "query",
              Placeholder: "Enter your query here",
              value: data["query"],
              validationRules: [
                {
                  required: true,
                  message: `Please enter your query`,
                },
              ],
            },
          ],
          [
            // {
            //   id: 4,
            //   type: "div",
            //   content: "Forgot Password ?",
            //   textColor: "orange",
            //   background: "",
            //   position: "end",
            //   event: "click",
            // },
          ],
          [
            {
              id: 5,
              label: "Submit Query",
              type: "button",
              position: "center", // position options (end, start, center)
              background: "Orange",
              textColor: "white",
              event: "submit",
            },
          ],
        ],
      },
    ],
  }

  return {
    SupportForm,
  }
}

export default GetSupportJson
