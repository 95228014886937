import React from "react"
// import Header from "./Component/Header"
import { WorkSpaceJson } from "../../../JsonFormat/WorkSpaceJson"
import IntroDetails from "./Component/IntroDetails"
import CardContainer from "./Component/CardContainer"
import Questions from "./Component/Questions"
import TrailInfo from "./Component/TrailInfo"
import Banner from "./Component/Banner"
import { useLocation, useNavigate } from "react-router-dom"
import UIModal from "../../Reuseable/UIModal"
import SupportForm from "./Component/Support"

function WorkSpace() {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <div>
      <UIModal
        open={pathname === "/support"}
        onClose={() => {
          navigate("/")
        }}
        size='sm'
        header={<h4>Get Support</h4>}
      >
        <SupportForm />
      </UIModal>

      {/* <Header header={WorkSpaceJson.header} /> */}
      <Banner />
      <IntroDetails content={WorkSpaceJson.introduction} />
      <CardContainer content={WorkSpaceJson.Card} />
      <Questions content={WorkSpaceJson.questions} />
      <TrailInfo content={WorkSpaceJson.trailInfo} />
    </div>
  )
}

export default WorkSpace
