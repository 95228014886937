const ChangePasswordData = (data) => {
    const NewPasswordForm = {
        totalpages: 1,
        pages: [
            {
                modalView: false,
                colSpan: [12, 12, 12],
                fields: [
                    // Row 1
                    [
                        {
                            id: 1,
                            label: "New Password",
                            type: "password",
                            name: "newPassword",
                            Placeholder: "Enter new password",
                            value: data["newPassword"],
                            validationRules: [
                                {
                                    required: true,
                                    message: "Please enter a new password",
                                },
                                {
                                    min: 8,
                                    message: "Password must be at least 8 characters",
                                },
                            ],
                            maxLength: 30
                        },
                    ],
                    // Row 2
                    [
                        {
                            id: 2,
                            label: "Confirm Password",
                            type: "password",
                            name: "confirmPassword",
                            Placeholder: "Confirm new password",
                            value: data["confirmPassword"],
                            validationRules: [
                                {
                                    required: true,
                                    message: "Please confirm your password",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('password not matched'));
                                    },
                                }),
                            ],
                            maxLength: 30
                        },
                    ],
                    // Row 3
                    [
                        {
                            id: 3,
                            label: "Submit",
                            type: "button",
                            position: "center",
                            background: "Orange",
                            textColor: "white",
                            event: "submit",
                        },
                    ],
                ],
            },
        ],
    };

    return {
        NewPasswordForm
    };
};

export default ChangePasswordData;

