const Privacy = () => {
  return (
    <div className="p-5">
      <h2>PRIVACY NOTICE</h2>

      <div className="p-1 my-4">
        <h6 className="mb-3">1. AGREEMENT TO TERM</h6>
        <p>
          These Terms of Use constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity (“you”) and Waila
          Technology, doing business as Waila Marketplace or The Waila
          Marketplace ("Waila Marketplace", “The Waila Marketplace”, “we”, “us”,
          or “our”), concerning your access to and use of the
          https://www.waila.co website as well as any other media form, media
          channel, mobile website or mobile application related, linked, or
          otherwise connected thereto (collectively, the “Site”). We are
          registered in Australia and have our registered office at Level 2 23
          Foster St, Surry Hills, New South Wales 2010. You agree that by
          accessing the Site, you have read, understood, and agree to be bound
          by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE
          TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE
          AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        </p>
        <p>
          Supplemental terms and conditions or documents that may be posted on
          the Site from time to time are hereby expressly incorporated herein by
          reference. We reserve the right, in our sole discretion, to make
          changes or modifications to these Terms of Use from time to time. We
          will alert you about any changes by updating the “Last updated” date
          of these Terms of Use, and you waive any right to receive specific
          notice of each such change. Please ensure that you check the
          applicable Terms every time you use our Site so that you understand
          which Terms apply. You will be subject to, and will be deemed to have
          been made aware of and to have accepted, the changes in any revised
          Terms of Use by your continued use of the Site after the date such
          revised Terms of Use are posted.
        </p>
        <p>
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. Accordingly, those persons who choose to
          access the Site from other locations do so on their own initiative and
          are solely responsible for compliance with local laws, if and to the
          extent local laws are applicable.
        </p>
        <p>
          The Site is not tailored to comply with industry-specific regulations
          (Health Insurance Portability and Accountability Act (HIPAA), Federal
          Information Security Management Act (FISMA), etc.), so if your
          interactions would be subjected to such laws, you may not use this
          Site. You may not use the Site in a way that would violate the
          Gramm-Leach-Bliley Act (GLBA).
        </p>
        <p>
          The Site is intended for users who are at least 18 years old. Persons
          under the age of 18 are not permitted to use or register for the Site.
        </p>
        <h6 className="my-3">2. INTELLECTUAL PROPERTY RIGHTS</h6>
        <p>
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property rights and unfair competition laws
          of the United States, international copyright laws, and international
          conventions. The Content and the Marks are provided on the Site “AS
          IS” for your information and personal use only. Except as expressly
          provided in these Terms of Use, no part of the Site and no Content or
          Marks may be copied, reproduced, aggregated, republished, uploaded,
          posted, publicly displayed, encoded, translated, transmitted,
          distributed, sold, licensed, or otherwise exploited for any commercial
          purpose whatsoever, without our express prior written permission.
        </p>
        <p>
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non-commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </p>
        <h6 className="my-3">3. USER REPRESENTATIONS</h6>
        <p>
          By using the Site, you represent and warrant that: (1) all
          registration information you submit will be true, accurate, current,
          and complete; (2) you will maintain the accuracy of such information
          and promptly update such registration information as necessary; (3)
          you have the legal capacity, and you agree to comply with these Terms
          of Use; (4) you are not a minor in the jurisdiction in which you
          reside; (5) you will not access the Site through automated or
          non-human means, whether through a bot, script or otherwise; (6) you
          will not use the Site for any illegal or unauthorized purpose; and (7)
          your use of the Site will not violate any applicable law or
          regulation.
        </p>
        <p>
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).
        </p>
        <h6 className="my-3">4. USER REGISTRATION</h6>
        <p>
          You may be required to register with the Site. You agree to keep your
          password confidential and will be responsible for all use of your
          account and password. We reserve the right to remove, reclaim, or
          change a username you select if we determine, in our sole discretion,
          that such username is inappropriate, obscene, or otherwise
          objectionable.
        </p>
        <h6 className="my-3">5. FEES AND PAYMENT</h6>
        <p>
          You may be required to purchase or pay a fee to access some of our
          services. You agree to provide current, complete, and accurate
          purchase and account information for all purchases made via the Site.
          You further agree to promptly update account and payment information,
          including email address, payment method, and payment card expiration
          date, so that we can complete your transactions and contact you as
          needed. We bill you through an online billing account for purchases
          made via the Site. Sales tax will be added to the price of purchases
          as deemed required by us. We may change prices at any time. All
          payments shall be in U.S. dollars.
        </p>
        <p>
          You agree to pay all charges or fees at the prices then in effect for
          your purchases, and you authorize us to charge your chosen payment
          provider for any such amounts upon making your purchase. If your
          purchase is subject to recurring charges, you consent to our charging
          your payment method on a recurring basis without requiring your prior
          approval for each recurring charge, until you notify us of your
          cancellation.
        </p>
        <p>
          We reserve the right to correct any errors or mistakes in pricing,
          even if we have already requested or received payment. We also reserve
          the right to refuse any order placed through the Site.
        </p>
        <h6 className="my-3">6. FREE TRIAL</h6>
        <p>
          We offer a 15-day free trial to new users who register with the Site.
          Your account will be automatically downgraded to the free version
          until payment is captured. Any accounts or data over the free version
          limit will be stored for 30 days. If your account is upgraded to a
          paid version before the end of the 30 day period, data and accounts
          will be restored (up to the data and account limit of the paid version
          selected). Any data or accounts in excess of the limit for the
          selected version will be deleted at the end of the 30 day period at
          the end of the free trial.
        </p>
        <h6 className="my-3">7. CANCELLATION</h6>
        <p>
          All purchases are non-refundable. You can cancel your subscription at
          any time by logging into your account or contacting us using the
          contact information provided below. Your cancellation will take effect
          at the end of the current paid term.
        </p>
        <p>
          If you are unsatisfied with our services, please email us at
          info@Waila Marketplace.com.
        </p>
        <h6 className="my-3">8. PROHIBITED ACTIVITIES</h6>
        <p>
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavours except those that are
          specifically endorsed or approved by us.
        </p>
        <p>As a user of the Site, you agree not to:</p>
        <ul>
          <li>
            Systematically retrieve data or other content from the Site to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any Content or enforce limitations on the use
            of the Site and/or the Content contained therein.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </li>
          <li>
            Use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Make improper use of our support services or submit false reports of
            abuse or misconduct
          </li>
          <li>
            Use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </li>
          <li>Engage in unauthorized framing of or linking to the Site.</li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Site or modifies, impairs, disrupts, alters, or interferes with the
            use, features, functions, operation, or maintenance of the Site.
          </li>
          <li>
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as “spyware” or
            “passive collection mechanisms” or “pcms”).
          </li>
          <li>
            Interfere with, disrupt, or create an undue burden on the Site or
            the networks or services connected to the Site.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Site to you.
          </li>
          <li>
            Attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site, or any portion of the Site.
          </li>
          <li>
            Copy or adapt the Site’s software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            Except as permitted by applicable law, decipher, decompile,
            disassemble, or reverse engineer any of the software comprising or
            in any way making up a part of the Site.
          </li>
          <li>
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Site, or using
            or launching any unauthorized script or other software.
          </li>
          <li>
            Use a buying agent or purchasing agent to make purchases on the
            Site.
          </li>
          <li>
            Make any unauthorized use of the Site, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretences.
          </li>
          <li>
            Use the Site as part of any effort to compete with us or otherwise
            use the Site and/or the Content for any revenue-generating endeavour
            or commercial enterprise.
          </li>
          <li>Sell or otherwise transfer your profile.</li>
        </ul>
        <h6 className="my-3">USER GENERATED CONTRIBUTIONS</h6>
        <p>
          The Site may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Site. As such, any Contributions you transmit may be treated as
          non-confidential and non-proprietary. When you create or make
          available any Contributions, you thereby represent and warrant that:
        </p>
        <ul>
          <li>
            The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li>
            You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Site, and other users of the Site to use your Contributions
            in any manner contemplated by the Site and these Terms of Use.
          </li>
          <li>
            You have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Site and these Terms of Use.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libellous, slanderous, or otherwise
            objectionable (as determined by us).
          </li>
          <li>
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li>
            Your Contributions are not used to harass or threaten (in the legal
            sense of those terms) any other person or to promote violenceAny use
            of the Site in violation of the foregoing violates these Terms of
            Use and may result in, among other things, termination or suspension
            of your rights to use the Site. against a specific person or class
            of people.
          </li>
          <li>
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </li>
          <li>
            Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li>
            Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation.
          </li>
        </ul>
        <p>
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.
        </p>
        <h6 className="my-3">10. CONTRIBUTION LICENSE</h6>
        <p>
          By posting your Contributions to any part of the Site, you
          automatically grant, and you represent and warrant that you have the
          right to grant, to us an unrestricted, unlimited, irrevocable right,
          and license to host, retitle, archive, store, cache, reformat,
          translate, transmit, excerpt (in whole or in part), and distribute
          such Contributions.
        </p>
        <p>
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Site; and (3) to pre-screen or delete any Contributions at any time
          and for any reason, without notice. We have no obligation to monitor
          your Contributions.
        </p>
        <h6 className="mb-3>">11. MOBILE APPLICATION LICENSE</h6>
        <p className="fw-bold">Use License</p>
        <p>
          If you access the Site via a mobile application, then we grant you a
          revocable, non-exclusive, non-transferable, limited right to install
          and use the mobile application on wireless electronic devices owned or
          controlled by you and to access and use the mobile application on such
          devices strictly in accordance with the terms and conditions of this
          mobile application license contained in these Terms of Use. You shall
          not: (1) except as permitted by applicable law, decompile, reverse
          engineer, disassemble, attempt to derive the source code of, or
          decrypt the application; (2) make any modification, adaptation,
          improvement, enhancement, translation, or derivative work from the
          application; (3) violate any applicable laws, rules, or regulations in
          connection with your access or use of the application; (4) remove,
          alter, or obscure any proprietary notice (including any notice of
          copyright or trademark) posted by us or the licensors of the
          application; (5) use the application for any revenue generating
          endeavor, commercial enterprise, or other purpose for which it is not
          designed or intended; (6) make the application available over a
          network or other environment permitting access or use by multiple
          devices or users at the same time; (7) use the application for
          creating a product, service, or software that is, directly or
          indirectly, competitive with or in any way a substitute for the
          application; (8) use the application to send automated queries to any
          website or to send any unsolicited commercial e-mail; or (9) use any
          proprietary information or any of our interfaces or our other
          intellectual property in the design, development, manufacture,
          licensing, or distribution of any applications, accessories, or
          devices for use with the application.
        </p>
        <p className="fw-bold">Apple and Android Devices</p>
        <p>
          The following terms apply when you use a mobile application obtained
          from either the Apple Store or Google Play (each an “App Distributor”)
          to access the Site: (1) the license granted to you for our mobile
          application is limited to a non-transferable license to use the
          application on a device that utilizes the Apple iOS or Android
          operating systems, as applicable, and in accordance with the usage
          rules set forth in the applicable App Distributor’s terms of service;
          (2) we are responsible for providing any maintenance and support
          services with respect to the mobile application as specified in the
          terms and conditions of this mobile application license contained in
          these Terms of Use or as otherwise required under applicable law, and
          you acknowledge that each App Distributor has no obligation whatsoever
          to furnish any maintenance and support services with respect to the
          mobile application; (3) in the event of any failure of the mobile
          application to conform to any applicable warranty, you may notify the
          applicable App Distributor, and the App Distributor, in accordance
          with its terms and policies, may refund the purchase price, if any,
          paid for the mobile application, and to the maximum extent permitted
          by applicable law, the App Distributor will have no other warranty
          obligation whatsoever with respect to the mobile application; (4) you
          represent and warrant that (i) you are not located in a country that
          is subject to a U.S. government embargo, or that has been designated
          by the U.S. government as a “terrorist supporting” country and (ii)
          you are not listed on any U.S. government list of prohibited or
          restricted parties; (5) you must comply with applicable third-party
          terms of agreement when using the mobile application, e.g., if you
          have a VoIP application, then you must not be in violation of their
          wireless data service agreement when using the mobile application; and
          (6) you acknowledge and agree that the App Distributors are
          third-party beneficiaries of the terms and conditions in this mobile
          application license contained in these Terms of Use, and that each App
          Distributor will have the right (and will be deemed to have accepted
          the right) to enforce the terms and conditions in this mobile
          application license contained in these Terms of Use against you as a
          third-party beneficiary thereof.
        </p>
        <h5 className="my-3">12. SUBMISSIONS</h5>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          ("Submissions") provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the right to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of any proprietary
          right in your Submissions.
        </p>
        <h5 className="my-3">13. THIRD-PARTY WEBSITES AND CONTENT</h5>
        <p>
          The Site may contain (or you may be sent via the Site) links to other
          websites ("Third-Party Websites") as well as articles, photographs,
          text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or
          originating from third parties ("Third-Party Content"). Such
          Third-Party Websites and Third-Party Content are not investigated,
          monitored, or checked for accuracy, appropriateness, or completeness
          by us, and we are not responsible for any Third-Party Websites
          accessed through the Site or any Third-Party Content posted on,
          available through, or installed from the Site, including the content,
          accuracy, offensiveness, opinions, reliability, privacy practices, or
          other policies of or contained in the Third-Party Websites or the
          Third-Party Content. Inclusion of, linking to, or permitting the use
          or installation of any Third-Party Websites or any Third-Party Content
          does not imply approval or endorsement thereof by us. If you decide to
          leave the Site and access the Third-Party Websites or to use or
          install any Third-Party Content, you do so at your own risk, and you
          should be aware these Terms of Use no longer govern. You should review
          the applicable terms and policies, including privacy and data
          gathering practices, of any website to which you navigate from the
          Site or relating to any applications you use or install from the Site.
          Any purchases you make through Third-Party Websites will be through
          other websites and from other companies, and we take no responsibility
          whatsoever in relation to such purchases which are exclusively between
          you and the applicable third party. You agree and acknowledge that we
          do not endorse the products or services offered on Third-Party
          Websites and you shall hold us harmless from any harm caused by your
          purchase of such products or services. Additionally, you shall hold us
          harmless from any losses sustained by you or harm caused to you
          relating to or resulting in any way from any Third-Party Content or
          any contact with Third-Party Websites.
        </p>
        <h6 className="my-3">14. DO WE MAKE UPDATES TO THIS NOTICE?</h6>
        <p>
          <i>
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </i>
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated 'Revised' date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <h6 className="my-3">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h6>
        <p>
          If you have questions or comments about this notice, you may email us
          at info@theTracTask.com or by post to:
        </p>
        <p>
          Waila Technology
          <br />
          2 23 Foster St
          <br />
          Surry Hills, New South Wales 2010
          <br />
          Australia
        </p>
        <h6 className="my-3">16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h6>
        <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please visit: http://www.theTracTask.com/delete-account.</p>
      </div>
    </div>
  );
};

export default Privacy;
