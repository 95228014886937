import React from "react"
import MuiAccordion from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import MuiAccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Styled from "styled-components"

const Accordion = Styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid #ccc`,
  // borderBottom: '1px solid #ccc',
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}))

const AccordionDetails = Styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "20px",
  transition: "height 2s ease-in-out",
}))

const AccordionSummary = Styled((props) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: "${themeColor}" }} />} {...props} />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    paddingTop: "20px",
    paddingBottom: "10px",
    color: "${themeColor}",
  },
}))

function UIAccordion({ value, isAllExpand }) {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <div>
      <Accordion expanded={expanded || isAllExpand} onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <p>{value?.question}</p>
        </AccordionSummary>
        <AccordionDetails>
          <p>{value?.answer}</p>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default UIAccordion
