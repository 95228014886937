import React from "react";
import Wrapper from "./style";
import { ButtonFilled } from "../../../../Reuseable/UIButtons";
import { useDispatch } from "react-redux";
import {
  setShowRegisterModal,
  setRegisterType,
} from "../../../../../redux/globalState";
import {
  GooglePlayButton,
  ButtonsContainer,
  AppStoreButton,
} from "react-mobile-app-button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ScrollToTop } from "../../../../../Assets/Constant";
import Carousels from "../../../../Reuseable/Carousels";

function IntroDetails({ content }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="container main-container">
        <div className="content">
          <div className="products my-2">
            {content?.ProductsIcons?.map((val, i) => {
              return (
                <Link to={val.url}>
                  <img className="product" alt="product" src={val.icon} />
                </Link>
              );
            })}
          </div>
          <br />
          <h1>{content?.Details?.heading}</h1>
          <p>{content?.Details?.summary}</p>
          <ButtonFilled
            onClick={() => {
              ScrollToTop();
              navigate("/register");
              dispatch(setRegisterType("organization"));
            }}
          >
            {content?.Details?.buttonName}
          </ButtonFilled>
        </div>
        <div className="image_container">
          {content.imageList ? (
            <Carousels imageList={content.imageList} />
          ) : (
            <img alt="svg" className="intro-image" src={content?.image} />
          )}
        </div>
      </div>
      {/* Mobile App Download Links */}
      {content?.mobile?.download ? (
        <div className="main-container">
          <ButtonsContainer>
            <GooglePlayButton
              url={content?.mobile?.gplayurl}
              theme={"light"}
              className={"custom-style"}
            />
            <AppStoreButton
              url={content?.mobile?.appleurl}
              theme={"light"}
              className={"custom-style"}
            />
          </ButtonsContainer>
        </div>
      ) : (
        <></>
      )}

      {/* <div className='container video-content'>
        <h2>{content?.VideoHeading}</h2>
        <video autoPlay loop muted>
          <source src={content?.Video} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div> */}
    </Wrapper>
  );
}

export default IntroDetails;
