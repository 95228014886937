import React, {useState} from 'react'
import { PaymentElement } from '@stripe/react-stripe-js'
import { ButtonFilled } from '../../Reuseable/UIButtons'
import Wrapper from './style'
import {useStripe, useElements} from '@stripe/react-stripe-js';
import { Spinner } from 'react-bootstrap';
import { Spin } from 'antd';

function PaymentProcess({showComponent}) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsLoading(true);
  
      const { paymentIntent, error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
           return_url: `http://localhost:3001`,
        },
        redirect: "if_required",
      });
   
      if (error?.type === "card_error" || error?.type === "validation_error") {
        setMessage(error?.message);
      }
      else if (paymentIntent?.status === 'succeeded') {
        setMessage('Payment succeeded!');
        console.log("success")
        // Perform actions for successful payment
        showComponent();
      } else {
        setMessage("An unexpected error occured.");
      }
      setIsLoading(false);
    }
    return (
        <Wrapper>
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <br/>
                <ButtonFilled type="submit" disabled={isLoading || !stripe || !elements} >{isLoading ? <Spin/> : "Pay now"}</ButtonFilled>
            </form>
        </Wrapper>
    )
}

export default PaymentProcess