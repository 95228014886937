import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import businessman from "../../Assets/Images/bussinessman.jpg"
import { Wrapper } from "./style"
import GridBuilder from "../UIBuilderEngine/GridBuilder"
import { getInvestorSupportJson } from "../../JsonFormat/InvestorSupportFormJson"

const InvestorSupport = () => {
  const [data, setData] = useState({})

  const { SupportForm } = getInvestorSupportJson(data)

  const handleFormChange = (e) => {
    console.log("e", e.target.value)
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmitForm = (e) => {
    console.log("e-->", e)
  }

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col xs={12} md={8}>
            <h1 className="heading">Investor Support</h1>

            <div className='form-container'>
              <GridBuilder
                formStructureJson={SupportForm}
                handleChange={handleFormChange}
                formSubmit={handleSubmitForm}
              />
            </div>
          </Col>
          <Col className='image-col' md={4}>
            <img src={businessman} alt='' className='business-image' />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default InvestorSupport
