import React from 'react'
import Wrapper from './style'
import { ButtonFilled } from '../../../../Reuseable/UIButtons'
import { useDispatch } from "react-redux"
import { setRegisterType } from "../../../../../redux/globalState"
import { ScrollToTop } from '../../../../../Assets/Constant'
import { useNavigate } from 'react-router-dom'


function TrailInfo({ content }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <Wrapper>
      <div className='start'></div>
      <div className='circle1'></div>
      <h1>{content?.title}</h1>
      <ButtonFilled onClick={() => {
        ScrollToTop()
        navigate('/register')
        dispatch(setRegisterType('organization'))
      }}>{content?.buttonName}</ButtonFilled>
      <div className='end'></div>
      <div className='circle2'></div>
    </Wrapper>
  )
}

export default TrailInfo