import { Card, CardHeader, TextField } from "@mui/material";
import { Checkbox } from "antd";
import { Divider } from "antd";
import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Wrapper } from "./style";
import Tables from "../../../../Reuseable/UITable";
import { normalizeNumber } from "../../../../../util/functions";
import { ButtonFilled } from "../../../../Reuseable/UIButtons";
import { useNavigate } from "react-router-dom";
import { ScrollToTop } from "../../../../../Assets/Constant";
import { useDispatch } from "react-redux";
import {
  setCloseDrawer,
  setUserPlan,
  setPlanType,
  setPlanCost,
} from "../../../../../redux/globalState";

const defaultCalucation = [
  {
    id: 1,
    product: "Tymeplus",
    monthlyCost: 7,
    // description: "HR management Tool",
    noOfUsers: 1,
    period: "12 months",
    totalCost: 84,
    selected: false,
  },
  {
    id: 2,
    product: "TracTask",
    monthlyCost: 7,
    // description: "Task Management and Project Management",
    noOfUsers: 1,
    period: "12 months",
    totalCost: 84,
    selected: false,
  },
  {
    id: 3,
    product: "FieldSale",
    monthlyCost: 7,
    // description: "Sales Management",
    noOfUsers: 1,
    period: "12 months",
    totalCost: 84,
    selected: false,
  },
  {
    id: 4,
    product: "CRM",
    monthlyCost: 7,
    // description: "Sales Management",
    noOfUsers: 1,
    period: "12 months",
    totalCost: 84,
    selected: false,
  },
];

const Calculator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [calculation, setCalculation] = React.useState(defaultCalucation);
  const isAnyItemSelected = calculation.some((item) => item.selected);

  const textFieldRef = React.useRef({});

  const handleTabKeyPress = (e, id) => {
    if (e.key === "Tab") {
      console.log("id", id);
      e.preventDefault();
      const nextId = getNextId(id);
      console.log("nextId-->", nextId);
      textFieldRef.current[nextId].focus();
    }
  };

  const getNextId = (currentId) => {
    const currentIndex = calculation.findIndex((item) => item.id === currentId);
    const nextIndex = (currentIndex + 1) % calculation.length;
    return calculation[nextIndex].id;
  };

  const isAllSelected = calculation.every((item) => item.selected);

  const handleSelect = (id) => {
    const newCalculation = calculation.map((item) => {
      if (item.id === id) {
        item.selected = !item.selected;
      }
      return item;
    });
    setCalculation(newCalculation);
  };

  const handleNoOfUsers = (e, id) => {
    const newCalculation = calculation.map((item) => {
      if (item.id === id) {
        item.noOfUsers = parseInt(normalizeNumber(e.target.value) || 0);

        if (item.noOfUsers <= 100) {
          item.monthlyCost = 7;
        } else if (item.noOfUsers <= 200) {
          item.monthlyCost = 6;
        } else if (item.noOfUsers <= 300) {
          item.monthlyCost = 5;
        } else {
          item.monthlyCost = 4;
        }

        item.totalCost = item.noOfUsers * 12 * item.monthlyCost;
      }
      return item;
    });
    setCalculation(newCalculation);
  };

  const tbody = calculation.map((item, index) => {
    return (
      <tr style={{ background: item.selected && "#49505729" }} key={index}>
        <td>
          <input
            className="form-check-input custom-checkbox"
            type="checkbox"
            checked={item.selected}
            id="myCheckbox"
            onChange={(_e) => {
              handleSelect(item.id);
            }}
          />
        </td>
        <td>{item.product}</td>
        <td>{item.monthlyCost} USD</td>
        {/* <td>{item.description}</td> */}
        <td>
          <TextField
            disabled={!item.selected}
            inputRef={(el) => {
              textFieldRef.current[item.id] = el;
            }}
            onKeyDown={(e) => handleTabKeyPress(e, item.id)}
            sx={{
              width: "120px",
              fontSize: "13px",
            }}
            variant="outlined"
            inputProps={{
              maxLength: 3,
            }}
            error={item.noOfUsers > 500}
            value={item.noOfUsers}
            type="text"
            onChange={(e) => handleNoOfUsers(e, item.id)}
            helperText={item.noOfUsers > 500 ? "Contact Sales" : ""}
            tabIndex={0}
          />
        </td>
        <td>{item.period}</td>
        <td>${item.totalCost.toLocaleString("en-US")}</td>
      </tr>
    );
  });

  const columnHeaders = [
    // <Checkbox
    //   checked={isAllSelected}
    //   onChange={(e) => {
    //     setCalculation(
    //       calculation.map((item) => {
    //         item.selected = e.target.checked
    //         return item
    //       })
    //     )
    //   }}
    // />
    <input
      className="form-check-input custom-checkbox"
      type="checkbox"
      checked={isAllSelected}
      id="myCheckbox"
      onChange={(e) => {
        setCalculation(
          calculation.map((item) => {
            item.selected = e.target.checked;
            return item;
          })
        );
      }}
    />,
    "Product",
    "Monthly Cost",
    // "Description",
    "No. of Users",
    "Period",
    "Total Cost",
  ];

  const [amount, setAmount] = React.useState({
    upfrontCost: 0,
    monthlyCost: 0,
    yearlyCost: 0,
  });

  useEffect(() => {
    let cost = calculation.reduce(
      (acc, item) => {
        if (item.selected) {
          acc.upfrontCost += item.totalCost;
          acc.monthlyCost += item.totalCost;
          acc.yearlyCost += item.totalCost;
        }
        return acc;
      },
      { upfrontCost: 0, monthlyCost: 0, yearlyCost: 0 }
    );

    cost.monthlyCost = cost.monthlyCost / 12;

    cost.upfrontCost = cost.upfrontCost - cost.upfrontCost * 0.1;

    setAmount(cost);
    dispatch(setPlanCost(cost))
  }, [calculation]);

  useEffect(() => {
    return () => {
      setCalculation(
        defaultCalucation.map((item) => ({ ...item, selected: false }))
      );
    };
  }, []);

  return (
    <Wrapper>
      <div className="container">
        <span>Cost Estimation Summary</span>
        {/* <br /> */}
        <hr />
        {/* <Divider
          style={{
            margin: "4px",
          }}
        /> */}

        <Container>
          <Row className="p-2">
            <Col xs={4}>
              <div className="estimation-heading">Upfront cost for year</div>
              <div className="discount">(10% Discount - For First Time)</div>
              <div className="cost">
                ${amount.upfrontCost.toLocaleString("en-US")}
              </div>
            </Col>

            <Col xs={4} className="pipe">
              <div className="estimation-heading">Monthly cost</div>
              <div className="cost">
                ${amount.monthlyCost.toLocaleString("en-US")}
              </div>
            </Col>

            <Col xs={4} className="pipe">
              <div className="estimation-heading">Total 12 months cost</div>
              <div className="fs-5 fw-bold">
                ${amount.yearlyCost.toLocaleString("en-US")}
              </div>
              {/* <div className=''>includes upfront cost</div> */}
            </Col>
          </Row>

          <div className="mt-1">
            <Tables
              loading={false} // Set to true to simulate loading state
              header={""}
              columns={columnHeaders}
              tbody={tbody}
              tbodyData={calculation}
              length={calculation.length}
              notfound="No data found"
              headerNoWrap={true}
            />
          </div>
        </Container>
      </div>
      <div className="container align-right">
        <ButtonFilled
          onClick={() => {
            const resetCalculation = calculation.map((item) => ({
              ...item,
              selected: false,
              noOfUsers: 1,
              totalCost: 84,
            }));
            setCalculation(resetCalculation);
          }}
        >
          Reset
        </ButtonFilled>
        <ButtonFilled
          disabled={!isAnyItemSelected}
          onClick={() => {
            navigate("/register");
            ScrollToTop();
            dispatch(setCloseDrawer(false));
            dispatch(setUserPlan(calculation));
            dispatch(setPlanType('recurring'));
          }}
        >
          {" "}
          Process{" "}
        </ButtonFilled>
      </div>
    </Wrapper>
  );
};

export default Calculator;
