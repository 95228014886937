import filedsaleDashboard from "../../Assets/Images/filedsaleDashboard.png";

import fieldsaleLogo from "../../Assets/Images/fieldsale.svg";

import feildsaleImage1 from "../../Assets/Images/FeildSale/feildsale_1.png";
import feildsaleImage2 from "../../Assets/Images/FeildSale/feildsale_2.png";
import feildsaleImage3 from "../../Assets/Images/FeildSale/feildsale_3.png";
import feildsaleImage4 from "../../Assets/Images/FeildSale/feildsale_4.png";
import feildsaleImage5 from "../../Assets/Images/FeildSale/feildsale_5.png";
import feildsaleImage6 from "../../Assets/Images/FeildSale/feildsale_6.png";
import feildsaleImage7 from "../../Assets/Images/FeildSale/feildsale_7.png";
import feildsaleImage8 from "../../Assets/Images/FeildSale/feildsale_8.png";
import feildsaleImage9 from "../../Assets/Images/FeildSale/feildsale_9.png";
import feildsaleImage10 from "../../Assets/Images/FeildSale/feildsale_10.png";

export const FieldSaleJson = {
  introduction: {
    ProductsIcons: [{ icon: fieldsaleLogo, url: "" }],
    Details: {
      heading:
        "In today's Marketplace, teams of all sizes utilize diverse tools to connect, create, and collaborate seamlessly.",
      summary:
        "A flexible, innovative solution for people and organisations to achieve more.",
      buttonName: "Get Started",
    },
    image: filedsaleDashboard,
    Video: "",
    imageList: [
      feildsaleImage1,
      feildsaleImage2,
      feildsaleImage3,
      feildsaleImage4,
      feildsaleImage5,
      feildsaleImage6,
      feildsaleImage7,
      feildsaleImage8,
      feildsaleImage9,
      feildsaleImage10,
    ],
    VideoHeading:
      "Productivity and collaboration tools for all the ways that we work.",
    mobile: {
      download: true,
      gplayurl: "https://play.google.com/store/apps/details?id=com.fieldsale",
      appleurl: "https://apps.apple.com/us/app/fieldsale/id1558720408",
    },
  },
};
