import React from "react"
import Wrapper from "./style"
import CloseIcon from "@mui/icons-material/Close"
import { ButtonOutlined, ButtonLink, ButtonFilled } from "../../../../Reuseable/UIButtons"
import UIMobileAccordion from "../../../../Reuseable/UIMobileAccordion"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setShowRegisterModal } from "../../../../../redux/globalState"
import { ScrollToTop } from "../../../../../Assets/Constant"

function MobileOffCanvas({ handleClose, Item, handleShow, handleMobileOffcanvas }) {
  const iconStyle = { color: "#474747", cursor: "pointer" }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleSignIn = () => {
    navigate("signin")
  }
  return (
    <Wrapper>
      <div className='float-right'>
        <CloseIcon onClick={handleClose} style={iconStyle} />
      </div>
      <div className='main-container'>
        <div>
          <div className='nav-item'>
            {Item?.MenuList.map((val, index) => {
              return (
                <UIMobileAccordion
                  handleShow={handleShow}
                  key={index}
                  value={val}
                  panelKey={`panel-${index}`}
                  handleMobileOffcanvas={handleMobileOffcanvas}
                />
              )
            })}
          </div>
        </div>
        <hr />
        <div className='header-btns'>
          {Item?.ActionButtonList.map((val) => {
            return (
              <>
                {val?.type === "outlined" && (
                  <ButtonOutlined onClick={() => {
                    navigate(val?.url)
                    handleClose()
                    ScrollToTop()
                  }}
                  >{val?.name}</ButtonOutlined>
                )}
                {val?.type === "link" && <ButtonLink>{val?.name}</ButtonLink>}
                {val?.type === "filled" && (
                  <ButtonFilled
                    onClick={() => {
                      handleClose()
                      navigate(val?.url)
                      ScrollToTop()
                    }}
                  >
                    {val?.name}
                  </ButtonFilled>
                )}
              </>
            )
          })}
        </div>
      </div>
      <div className="overlay" onClick={handleClose}></div>
    </Wrapper>
  )
}

export default MobileOffCanvas
