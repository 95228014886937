import Styled from "styled-components"
import { themeColor } from "../../../Assets/Constant"

const Wrapper = Styled.div`
   .add_insident_container{
        padding:5%;        
   }
   .form_container{
    padding: 1.5rem;
    /* border: 1px solid grey; */
    border-radius: 5px;
    box-shadow: 0px 0px 16px 0px #C2FAE5;
   }
   
`;

export default Wrapper
