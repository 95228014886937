import Styled from "styled-components"
import { themeColor } from "../../../../../Assets/Constant"

const Wrapper = Styled.div`
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center; 
    min-height: 100px;
    background-color: ${themeColor} !important;
    padding: 10px;
    font-size: 18px;
    .banner_h{        
        font-weight: 700;
        font-size:3rem;
    }
    .banner_p{
        text-align: center;
        color: #474747;   
        font-size:1rem; 
    }

    @media (max-width: 1000px) {
        height: 100%;
    }


   
`

export default Wrapper
