import { countryList } from "../../Assets/Constant"

export const OrganizationFormJson = {
  totalpages: 1,
  pages: [
    {
      modalView: false, // For showing modal in Popup
      colSpan: [12, 12, 12, 12], // for 1 column 12, 2 columns 6, 3 columns 4, 4 columns 3 (max 4 columns)
      fields: [
        [
          {
            id: 1,
            label: "Company Name",
            type: "text",
            name: "companyname",
            Placeholder: "Company Name",
            value: "",
            validationRules: [
              {
                required: true,
                message: `Please enter company name`,
              },
            ],
            onKeyDown: /^[a-zA-Z\s]+$/,
            maxLength: 30
          },
        ], // Row 1
        [
          {
            id: 2,
            label: "Email",
            type: "text",
            name: "email",
            Placeholder: "example@gmail.com",
            value: "",
            validationRules: [
              {
                required: true,
                message: `Please enter email`,
              },
              {
                pattern: /^\S+@\S+\.\S+$/,
                message: "Please enter a valid email address",
              },
            ],
            maxLength: 50
          },
        ],
        [
          {
            id: 3,
            label: "Phone number(optional)",
            type: "text",
            name: "phone",
            Placeholder: "Phone",
            value: "",
            onKeyDown: /^[0-9]$/,
    
          },
        ],
        [
          {
            id: 4,
            label: "Your Domain",
            type: "url",
            name: "domain",
            Placeholder: "Domain",
            value: "",
            suffixUrl: ".waila.co",
            validationRules: [
              {
                required: true,
                message: `Please enter your domain`,
              },
            ],
            onKeyDown: /^[a-z0-9]+$/,
            maxLength: 30
          },
        ],
        [
          {
            id: 5,
            label: "No. of Employee",
            type: "text",
            name: "noofemp",
            Placeholder: "No. of Employee",
            value: "",
            validationRules: [
              {
                required: true,
                message: `Please enter no. of employee`,
              },
            ],
            onKeyDown: /^[0-9]$/,
          },
        ],
        [
          {
            id: 6,
            label: "Country",
            type: "select",
            name: "country",
            Placeholder: "Select",
            value: "",
            options: countryList,
            validationRules: [
              {
                required: true,
                message: `Please select the country`,
              },
            ],
          },
        ],
        [
          {
            id: 7,
            label: "Terms & Conditions",
            type: "checkbox",
            name: "termsAgreement",
            value: "",
            validationRules: [
              {
                required: true,
                message: `Please agree to the Terms & Conditions`,
              },
            ],
            link: '/privacy',
          },
        ],
        [
          {
            id: 8,
            label: "Register",
            type: "button",
            position: "center", // position options (end, start, center)
            background: "Orange",
            textColor: "white",
            event: "submit",
          },
        ],
      ],
    },
  ],
}
