import React, { useState } from "react"
import Wrapper from "./style"
import SignInFormData from "../../../JsonFormat/SignInJson"
import GridBuilder from "../../UIBuilderEngine/GridBuilder"
import OTPVerification from "../OTPVerification"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { IconButton } from "@mui/material"
import ChangePasswordData from "../../../JsonFormat/ChangePasswordFormJson"
import { useDispatch } from "react-redux"
import { setAlertMessage, setRegisterType } from "../../../redux/globalState"
import { APIURL, ScrollToTop, applicationsUrl, domainName } from "../../../Assets/Constant"
import axios from "axios"
import { message } from "antd"
import SwitchApp from "../SwitchApp"
import UIModal from "../../Reuseable/UIModal"
import { useNavigate } from "react-router-dom"

function SignIn() {
  const [data, setData] = useState({})
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [showComponent, setShowComponent] = useState(1)
  const [showSwitchModal, setShowSwitchModal] = useState(false)
  const [productList, setProductList] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleSubmitForm = async (form) => {
    if (showComponent === 2) {
      setEmail(form.email)
      forgotPasswordAPI(form.email)
      return
    }
    if (showComponent === 4) {
      const payload = {
        email: email?.toLowerCase(),
        password: form.newPassword,
      }
      resetPassword(payload)
      return
    }
    // sign in API
    if (showComponent === 1) {
      setEmail(form.email)
      const payload = {
        email: form?.email?.toLowerCase(),
        password: form?.password,
      }
      setLoading(true)
      try {
        const response = await axios.post(`${APIURL}/user/login`, payload)
        if (response.data.status == true) {
          setLoading(false)

          if (response.data?.authorizationToken) {
            localStorage.setItem("token", response.data.authorizationToken)
            window.location.href =
              applicationsUrl.tymeplus + "&token=" + response.data.authorizationToken
          }

          if (response.data.productlist) {
            setProductList(response.data.productlist)
            // setProductList([]) //? so that the popup does not come
            setShowSwitchModal(true)
            return
          }
          return
        }
        if (response.data.status == false) {
          setLoading(false)
          dispatch(
            setAlertMessage({
              type: "error",
              message: response.data.message,
            })
          )
        }
      } catch {
        setLoading(false)
      }
    }
  }
  const { SignInForm, ForgotForm } = SignInFormData(data)
  const { NewPasswordForm } = ChangePasswordData(data)
  const handleFormChange = (e) => {
    console.log("e", e.target.value)
    setData({
      ...data,
      [e.target.name]: e.target.value,
    })
  }

  const handleForgotPassword = () => {
    setShowComponent(2)
  }

  const handleOtpVerification = async (otp) => {
    const payload = {
      email: email,
      otp: otp,
    }
    try {
      setLoading(true)
      const response = await axios.post(`${APIURL}/user/verify-otp`, payload)
      if (response.data.status == true) {
        setLoading(false)
        setShowComponent(4)
      } else {
        setLoading(false)
        dispatch(
          setAlertMessage({
            type: "error",
            message: "OTP Invalid! Please enter the correct OTP",
          })
        )
      }
    } catch {
      setLoading(false)
    }
  }

  const resetPassword = async (payload) => {
    try {
      setLoading(true)
      const response = await axios.post(`${APIURL}/user/reset-password`, payload)
      if (response.data.status == true) {
        setLoading(false)
        setShowComponent(1)
        dispatch(
          setAlertMessage({
            type: "success",
            message: "Password changed successfully",
          })
        )
      } else {
        setLoading(false)
      }
    } catch {
      setLoading(false)
    }
  }

  const sendOTP = async (email) => {
    const payload = {
      email: email?.toLowerCase(),
    }
    try {
      setLoading(true)
      const response = await axios.post(`${APIURL}/user/send-otp`, payload)
      if (response.data.status == true) {
        setLoading(false)
        setShowComponent(3)
      } else {
        setLoading(false)
      }
    } catch {
      setLoading(false)
    }
  }

  const forgotPasswordAPI = async (email) => {
    const payload = {
      email: email?.toLowerCase(),
    }
    try {
      setLoading(true)
      const response = await axios.post(`${APIURL}/user/reset-otp`, payload)
      if (response.data.status == true) {
        setLoading(false)
        setShowComponent(3)
      } else {
        setLoading(false)
        dispatch(
          setAlertMessage({
            type: "error",
            message: response.data.message,
          })
        )
        // message.error('OTP Invalid! Please enter the correct OTP');
      }
    } catch {
      setLoading(false)
    }
  }

  const goback = () => {
    if (showComponent > 1) {
      setShowComponent(showComponent - 1)
    }
  }

  const submitLogin = async (productid) => {
    setShowSwitchModal(false)
    const payload = {
      email: email?.toLowerCase(),
      productid: productid,
    }
    try {
      const response = await axios.post(`${APIURL}/user/switchproduct`, payload)

      if (response.data?.authorizationToken) {
        localStorage.setItem("token", response.data.authorizationToken)
        // message.success("Login Successfully")
        dispatch(
          setAlertMessage({
            type: "success",
            message: "Login Successfully",
          })
        )
        window.location.href = "http://localhost:3001/"
      }
    } catch (e) {
      console.log("err-->", e)
    }
  }
  return (
    <Wrapper>
      <div className='container'>
        <div className='main-container'>
          <div className='form-container'>
            {showComponent !== 1 && showComponent !== 4 && (
              <div className='float-left back-btn'>
                <IconButton onClick={goback}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
            )}
            <h3>
              {showComponent === 1
                ? `Sign In ${domainName ? `to ${domainName}` : ""}`
                : showComponent === 2
                ? "Forgot Password"
                : showComponent === 3
                ? "Verification"
                : "Reset Password"}
            </h3>
            {showComponent === 2 && (
              <p className='sub-title'>
                Enter your registered email address to change your waila account password.
              </p>
            )}
            {showComponent !== 3 && (
              <GridBuilder
                formStructureJson={
                  showComponent === 1
                    ? SignInForm
                    : showComponent === 2
                    ? ForgotForm
                    : NewPasswordForm
                }
                handleChange={handleFormChange}
                formSubmit={handleSubmitForm}
                loading={loading}
                //  handleClick={handleGoBack}
                handleForgotPassword={handleForgotPassword}
              />
            )}
            {showComponent === 3 && (
              <OTPVerification
                handleOtpVerification={handleOtpVerification}
                email={data?.email}
                resendOtp={sendOTP}
                loading={loading}
              />
            )}
            <br />
            {showComponent === 1 && (
              <p>
                Don't have an account?{" "}
                <a
                  onClick={() => {
                    ScrollToTop()
                    navigate("/register")
                    dispatch(setRegisterType("organization"))
                  }}
                >
                  Register
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
      <UIModal
        open={showSwitchModal}
        onClose={() => {
          setShowSwitchModal(false)
        }}
        header={<div></div>}
        size='md'
      >
        <SwitchApp productList={productList} submitLogin={submitLogin} />
      </UIModal>
    </Wrapper>
  )
}

export default SignIn
