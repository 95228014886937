import React, { useState, useEffect } from "react";
import CarouselWrapper from "./style";

const Carousels = ({ imageList }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % imageList.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <CarouselWrapper>
      <div className="fading-images-container">
        {imageList.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            className={index === currentImage ? "visible" : "hidden"}
          />
        ))}
      </div>
    </CarouselWrapper>
  );
};

export default Carousels;
